.provider-dashboard {
    @include flex-item(flex-start, flex-start);

    @media (max-width: 767px) {
        flex-direction: column;
    }

    .column-side {
        width: 200px;
        height: calc(100vh - 50px);
        overflow-y: auto;
        background: $white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        @media (max-width: 767px) {
            width: 100%;
            height: auto;
            box-shadow: none;
        }

        .logo-container {
            padding: 18px 10px 18px 25px;
            border-bottom: 1px solid rgba($black, 0.2);

            .header-logo {
                justify-content: flex-start;
            }

            @media (max-width: 767px) {
                @include fixedPosition($white);
                @include flex-item(space-between, center);
                padding: 5px 15px;
                min-height: 60px;
                z-index: 99999;
            }
        }

        .nav-provider {
            padding-top: 25px;

            ul.nav {
                li.nav-item {
                    width: 100%;
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    a.nav-link {
                        @include flex-item(flex-start, center);
                        @include font(400, 16px, 19px);
                        color: $black;
                        text-transform: capitalize;
                        border-left: 5px solid $white;
                        padding: 11px 10px 11px 30px;

                        br {
                            display: none;
                        }

                        &:hover,
                        &.active {
                            color: $main-link-color;
                            font-weight: 500 !important;
                            border-left-color: $main-link-color;
                            background: rgba($main-link-color, 0.1);
                        }
                    }
                }
            }

            @media (max-width: 767px) {
                padding: 75px 15px 15px;

                ul.nav {
                    justify-content: space-around;

                    li.nav-item {
                        width: calc((100% - 20px) / 3);
                        margin-bottom: 0;
                        margin-right: 10px;

                        a.nav-link {
                            @include font(500, 14px, 16px);
                            border-left: 0;
                            padding: 10px 10px;
                            justify-content: center;
                            background-color: rgba($grey, 0.15);
                            border-radius: 4px;
                            text-align: center;
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            @media (max-width: 420px) {
                ul.nav {
                    justify-content: space-between;

                    li.nav-item {
                        a.nav-link {
                            br {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    .column-main {
        width: calc(100% - 200px);
        padding: 15px 30px;

        .section-user-log {
            @include flex-item(space-between, center);
            margin-bottom: 25px;

            button {
                min-height: 36px;
            }
        }

        @media (max-width: 1024px) {
            padding: 15px 20px;
        }

        @media (max-width: 767px) {
            width: 100%;
            padding: 0 15px 20px;
        }
    }

    .section-cards-care {
        .card-right {
            .ratingBox__no {
                height: 24px;
                min-width: 24px;
                font-size: 14px;
                padding: 3px 10px;
            }
        }
    }
}

.user-box {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
}

.dashboardHeader {
    padding: 15px 30px;
    border-bottom: 1px solid #ddd;

    .MuiGrid-container {
        align-items: center;
    }

    .section-user-log {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .nav {
        justify-content: center;

        .nav-item {
            a {
                font-weight: 700 !important;
                color: #63120d;
                font-size: 16px !important;
                line-height: 21px;
            }

            div a {
                color: #2e475d !important;
                font-weight: 400 !important;
            }

            ul {
                list-style-type: none;

                li>a {
                    font-weight: 700 !important;
                    color: #63120d !important;
                }
            }

        }
    }

    .section-user-log {
        &>.btn-theme {
            font-family: 'Lexend Deca', sans-serif !important;
            background: #63120d;
            border-radius: 5px !important;
            padding: 8px 30px;
            font-size: 14px;
            font-weight: 500 !important;
            border: 2px solid #63120d !important;
            color: #fff !important;
            transition: all 0.2s ease;
            min-width: 0 !important;
            border-radius: 50px !important;
            overflow: hidden;
            position: relative;
            margin-right: 12px;

            span {
                color: #fff !important;
            }

            &::after {
                content: '';
                height: 100%;
                width: 100%;
                background-color: #fff;
                display: block;
                position: absolute;
                top: 0;
                left: 100%;
                transition: all 0.5s ease;
                z-index: 0;
            }

            // &:hover {
            //     color: #63120d !important;
            //     &:hover::after {
            //         left: 0;
            //         border-radius: 50px !important;
            //     }
            //     span {
            //         color: #63120d !important;
            //     }
            // }
        }
    }

    .user-log-dropdown {
        .user {
            box-shadow: none;
            position: relative;

            &::after {
                content: '';
                height: 10px;
                width: 10px;
                display: block;
                border-left: 3px solid #63120d;
                border-bottom: 3px solid #63120d;
                transform: rotate(-45deg);
                margin-left: 10px;
                margin-bottom: 5px;
            }
        }
    }
}

.dashboard-content {
    .MuiGrid-container>div {
        width: 100%;
    }
}

.dashboardHeadMenu {
    display: flex;
    align-items: center;
    justify-content: end;
}