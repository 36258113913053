@import '../../../assets/styles/_colors.scss';
@import '../../../assets/styles/_mixin.scss';

.banner-wrapper-home-provider {
    background-size: cover;
    position: relative;
    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    .container-items {
        @include flex-d(flex-end, center, column);
        min-height: 550px;
        position: relative;
        z-index: 9;
        padding-bottom: 200px;
    }
    .title-main {
        @include font(700, 60px !important, 70px !important);
        color: #000;
        margin-bottom: 20px !important;
        margin-top:10px;
        text-transform: none;
        @media (max-width: 1199.98px) {
            font-size: 50px !important ;
        }
        @media (max-width: 991px) {
            font-size: 40px !important ;
        }
        @media (max-width: 576px) {
            font-size: 32px !important ;
        }
    }
    .title-main-second {
        @include font(200, 24px !important, 34px !important);
        color: #000;
        margin-bottom: 20px !important;
        text-transform: none;
        text-align:center;
        margin-top:170px
    }
    .title-main-second-plan{
       margin-top:50px !important;
    }
    .section-search-and-buttons {
        width: 720px;
        .search-holder {
            position: relative;
            margin-bottom: 30px;
            .form-control {
                min-height: 56px;
                border-radius: 6px;
                &::placeholder {
                    font-weight: 500;
                }
            }
            .icon-search {
                position: absolute;
                width: 20px;
                height: 20px;
                right: 18px;
                top: 20px;
            }
        }
    }
    .buttons-container {
        @include flex-item(space-between, center);
        .btn-theme {
            min-width: 350px;
            min-height: 56px;
            text-transform: capitalize;
            border-width: 2px;
        }
    }
}
/* Media query for banner */
  // Large devices (desktops, 1200px and up)
  @media (max-width: 1200px) { 
    .banner-wrapper-home {
        .title-main {
            font-size: 42px !important; 
            line-height: 60px !important;
            text-align: center; 
        }
    }
 }
@media (max-width: 767px) {
    .banner-wrapper-home {
        .title-main {
            font-size: 30px !important;
            line-height: 35px !important;
            margin-bottom: 24px !important;
            text-align: center; 
        }
        .section-search-and-buttons {
            width: 100%;
            .search-holder {
                margin-bottom: 32px;
                .form-control {
                    font-size: 14px;
                    line-height: 16px;
                    padding-right: 38px;
                }
            }
        }
        .buttons-container {
            flex-direction: column;
            .btn-theme {
                min-width: 100%;
                margin-bottom: 16px;
                font-size: 16px;
                line-height: 19px;
            }
        }
        .container-items {
            padding-bottom: 8px;
        }
    }
}
@media (max-width: 600px){
    .banner-wrapper-home {
        background: url("../../../assets/images/banner-mobile.svg") no-repeat center;
        background-size: cover;
    }
}
.provider-top{
    margin-top: -10px;
}
.provider-containers{
    display:flex;
    align-items: center;
    align-content: center;
    flex-flow: column wrap;
    justify-content: center;
    text-align: center;
    margin-top:25px;
}
.provider-containers p{
    display:flex;
    align-items: center;
    align-content: center;
    flex-flow: column wrap;
    justify-content: center;
    text-align: center;
}
.provider-containers ul{
    display:flex;
    align-items: center;
    align-content: center;
    flex-flow: column wrap;
    justify-content: center;
    text-align: center;
    list-style-type: none;
}
hr.new4{
   border: 2px solid #63120d;
   margin-top: 35px;
}