.wrapper {
    display: block;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    /* top layer */
    z-index: 9999;
}

.overlay {
    display: none;
    position: fixed;
    /* full screen */
    width: 100vw;
    height: 100vh;
    /* transparent black */
    background: rgba(0, 0, 0, 0.7);
    /* middle layer, i.e. appears below the sidebar */
    z-index: 998;
    opacity: 0;
    /* animate the transition */
    transition: all 0.5s ease-in-out;
}

/* display .overlay when it has the .active class */
.overlay.active {
    display: block;
    opacity: 1;
}

#dismiss {
    width: 35px;
    height: 35px;
    position: absolute;
    /* top right corner of the sidebar */
    top: 10px;
    right: 10px;
}

// 08-08-2000


.video-card-block {
    border: 1px solid #ddd;
    border-radius: 15px;
    margin-bottom: 30px;
    height: calc(100% - 30px);

    &>div {
        width: 100% !important;
    }

    iframe {
        border-radius: 15px 15px 0px 0px;
    }

    .Video-item {
        .icon {
            margin-bottom: 10px;

            svg {
                fill: #eeba00;
            }
        }

        padding: 30px;

        p {
            font-size: 16px;
            line-height: 27px;
        }
    }

    .video-user-profile-block {
        display: flex;
        align-items: center;
        margin-top: 15px;

        img {
            width: 54px;
            border-radius: 50%;
            height: 54px;
            margin-right: 20px;
        }

        h2 {
            margin-bottom: 0 !important;
            font-size: 14px !important;
            line-height: 20px !important;

            span {
                display: block;
                color: #6d6d6d;
            }
        }

    }
}

.center-heading {
    padding: 100px 0px;
    background-color: #fff3e4;
    margin-bottom: 40px;
    text-align: center;
}

.MuiSelect-select:focus {
    border-radius: 0;
    background-color: transparent !important;
}

.country-dropdown-menu {
    display: flex;
    border: 1px solid #ddd;
    padding: 15px 18px;
    margin-bottom: 20px;
    border-radius: 12px;
    flex-wrap: wrap;

    &>div {
        margin: 5px 0;
    }

    label {
        font-weight: 500 !important;
        background: #fff;
        position: absolute;
        z-index: 1;
        font-size: 13px;
        padding: 1px 5px;
        top: -10px;
        left: 12px;
    }

    select,
    .MuiInputBase-root {
        border-radius: 6px;
        border: 1px solid #ddd;
        padding: 8px 10px;
        margin: 0 10px 0 0;
        min-width: 160px;
    }

    .MuiSelect-select.MuiSelect-select {
        padding-right: 24px;
        font-size: 15px;
    }

}

.language-block {
    .MuiButton-label {
        font-weight: 700 !important;
        color: #63120d !important;
        font-size: 18px;
        line-height: 21px;
    }

    .MuiSvgIcon-fontSizeSmall {
        fill: #63120d;
    }

    .MuiButtonBase-root {
        &:hover {
            background-color: transparent !important;
        }
    }
}

.mapImg {
    margin-right: 10px;
}

.items-center {
    align-items: center;
}

.newsletterTemplate {
    padding-top: 70px;


    &__title {
        color: #63120d;
        font-size: 70px !important;
        line-height: 72px !important;
        font-weight: 600 !important;
        text-transform: none;
    }

    p {
        line-height: 26px;
        font-size: 16px;
        color: #606469;
        padding-left: 20px;
        border-left: 2px solid #69bd60;
    }

    form {
        margin-top: 18px;
        max-width: 400px;

        input {
            min-height: 45px;
            border: 1px solid #ccc;
        }

        .form-group {
            margin-bottom: 12px !important;
        }

        .btn-submit {
            width: 100%;
            min-height: 45px;
        }
    }

    &__selector {
        margin-top: 50px;
        max-width: 400px;

        select {
            height: 45px;
            border: 1px solid #ccc;
            box-sizing: border-box;
            padding: 8px 15px;
        }

        .MuiFormLabel-root {
            line-height: 15px !important;
            font-family: 'Lexend Deca', sans-serif;
            font-size: 16px;
            background: #fff;
            padding: 0px 3px;
        }
    }

    &__image {
        max-width: 400px;
        margin: 0 auto;
        text-align: center;
        position: relative;
        padding: 0 50px 50px 0;

        img {
            width: 100%;
            margin: 0 auto;
            position: relative;
            z-index: 1;
            border: 2px solid #eea596;
            border-radius: 20px 20px 20px 0;
        }

        &::after {
            content: '';
            height: calc(100% - 50px);
            width: calc(100% - 50px);
            border: 10px solid #63120d;
            opacity: 25%;
            display: block;
            position: absolute;
            left: 50px;
            top: 50px;
            z-index: 0;
            border-radius: 20px 20px 20px 0;
        }
    }
}

.providerHeader {
    .signInBlock {
        display: flex;
        align-items: center;
        // margin-left: 15px;
    }

    .signInText {
        margin: 0;
        margin-right: 8px;
    }

    li.nav-item {
        margin-right: 20px !important;
    }
}

@media only screen and (max-width: 991px) {
    .langModel .MuiList-root {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .newsletterTemplate__image {
        margin-top: 30px;
    }

    .nav {
        width: 100%;
    }
}

@media only screen and (max-width: 660px) {
    .langModel .MuiList-root {
        grid-template-columns: 1fr 1fr;
        padding: 0 25px 25px;
    }

    .langModel__header {
        padding: 25px;
    }

}

.ratingIcons {
    display: flex;
    gap: 5px;
    margin-bottom: 15px;
}



.MuiMenu-list {
    li.MuiListItem-root {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;

        img {
            margin-right: 10px;
        }
    }
}

.countrymodal {
    .MuiBox-root {
        border: none;
        border-radius: 12px;
    }

    .country-dropdown-menu {
        padding: 0;
        border: none;
        margin: 0;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
}

.country-dropdown-menu {
    .MuiSelect-selectMenu {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;

        img {
            margin-right: 10px;
        }
    }
}