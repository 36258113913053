.section-radio-buttons.form-group {
    .radio-buttons {
        display: flex;
        width: 100%;
        border: 1px solid rgba($main-btn-color, 0.5);
        border-radius: 4px;
        > div {
            width: 50%;
            position: relative;
            min-height: 36px;
            label {
                @include flex-item(center, center);
                @include font(400, 16px, 19px);
                color: rgba($black, 0.7);
                margin-bottom: 0;
                text-transform: capitalize;
                cursor: pointer;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($white, 0.1);
                border-radius: 4px 0 0 4px;
            }
            input[type="radio"] {
                @include input-appearance();
                @include no-user-select();
                opacity: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                &:checked + label {
                    background-color: $radio-btn-color;
                    color: $main-btn-color;
                }
            }
            &:last-child {
                label {
                    border-radius: 0 4px 4px 0;
                }
            }
        }
    }
}