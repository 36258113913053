@mixin flex-item($value_h, $value_v) {
    display: flex;
    align-items: $value_v;
    justify-content: $value_h;
}

@mixin flex-d($value_h, $value_v, $direction) {
    display: flex;
    align-items: $value_v;
    justify-content: $value_h;
    flex-direction: $direction;
}

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin custom-scroll {
    &::-webkit-scrollbar {
      width: 0.45em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-text;
      outline: 1px solid $gray-text;
      border-radius: 50vh;
    }
}

@mixin circle($size) {
    width : $size;
    height: $size;
    border-radius: 50%;
}

@mixin capsule($width, $height) {
    width : $width;
    height: $height;
    border-radius: 50vh;
}

@mixin input-appearance {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@mixin no-user-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

@mixin placeholder-color($color) {
    &::-webkit-input-placeholder {
      color: $color;
      font-weight: 400;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: $color;
      font-weight: 400;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $color;
      font-weight: 400;
    }
    &:-ms-input-placeholder {
      color: $color;
      font-weight: 400;
    }
}

@mixin font($weight, $size, $lineHeight) {
    font-family: $default-font;
    font-weight: $weight !important;
    font-size: $size;
    line-height: $lineHeight;
}

@mixin fixedPosition($bg) {
    position: fixed;
    width: 100%;
    z-index: 9;
    background-color: $bg;
}

@mixin center($position) {
    position: absolute;
    @if $position == 'verticle' {
        top: 50% !important;
        -webkit-transform: translateY(-50%) !important;
        -ms-transform: translateY(-50%) !important;
        transform: translateY(-50%) !important;
    }
    @else if $position == 'horizontal' {
        left: 50% !important;
        -webkit-transform: translateX(-50%) !important;
        -ms-transform: translateX(-50%) !important;
        transform: translate(-50%) !important;
    }
    @else if $position == "both" {
        left: 50% !important;
        top: 50% !important;
        -webkit-transform: translate(-50%, -50%) !important;
        -ms-transform: translate(-50%, -50%) !important;
        transform: translate(-50%, -50%) !important;
    }
}

@mixin positioned($top, $left) {
    position: absolute;
    content: "";
    top: $top;
    left: $left;
}

@mixin background-gradient($start-color, $end-color, $orientation) {
    background: $start-color;

    @if $orientation == 'vertical' {
      	background: linear-gradient(to bottom, $start-color, $end-color);
    } @else if $orientation == 'horizontal' {
      	background: linear-gradient(to right, $start-color, $end-color);
    } @else {
      	background: radial-gradient(ellipse at center, $start-color, $end-color);
    }
}

@mixin box-shadow($box-shadow) {
	-webkit-box-shadow: $box-shadow;
	-ms-box-shadow: $box-shadow;
	-moz-box-shadow: $box-shadow;
	box-shadow: $box-shadow;
}

@mixin small-dot {
    position: relative;
    margin-right: 16px; 
    &:after {
        position: absolute;
        content: "";
        width: 2px;
        height: 2px;
        background-color: rgba($black, 0.5);
        right: -10px;
        top: 9px;
        @media (max-width: 650px) {
            top: 6px;
        }
    }
}

// need to be updated ongoing

@mixin breakpoint($point) {
    @if $point == tablet {
      @media (max-width: 1020px) { @content ; }
   }
    @else if $point == mobile {
      @media (max-width: 620px) { @content ; }
   }
}

@mixin box-sizing($border-box) {
    box-sizing: $border-box;
}

@mixin no-outline {
    outline:none;
}

@mixin no-decoration {
    text-decoration:none;
}

@mixin hidden {
    display:none;
}

@mixin display($display) {
    display: $display;
}

@mixin flex {
    display: flex;
    display: -ms-flexbox;
}

@mixin justify-content($justify-content) {
    justify-content: $justify-content;
}

@mixin align-items($align-items) {
    align-items: $align-items;
}

@mixin relative {
    position: relative;
}

@mixin  pointer {
    cursor: pointer;
}

@mixin border-radius($border-radius) {
    border-radius: $border-radius;
}

@mixin flex-direction ($flex-direction) {
    flex-direction: $flex-direction;
}

@mixin object-fit ($object-fit) {
    object-fit: $object-fit;
}

@mixin transition($transition) {
    -webkit-transition: $transition;
    -ms-transition: $transition;
    -moz-transition: $transition;
    transition: $transition;
}