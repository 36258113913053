.reset-password-container {
    max-width: 600px;
    margin: 100px auto 0;
    .title-main {
        margin-bottom: 35px;
        text-align: center;
    }
    .btn-to-click-in-fp {
        margin-top: 40px;
    }
    @media (max-width: 600px) {
        padding: 0 15px;
    }
}