@import "../../../assets/styles/_colors.scss";
@import "../../../assets/styles/_mixin.scss";


.section-empowerment {
    padding: 65px 0 0;

    h2 {
        @include font(700, 42px !important, 49px !important);
        margin-bottom: 40px !important;
        text-align: center;
        text-transform: none;
    }

    .empower-list {

        div.col-6,
        div.col-sm-4,
        div.col-12,
        div.col-sm-8 {
            padding: 0 10px;
        }

        .empower-list-item {
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
            cursor: pointer;

            a {
                display: block;
            }

            h3 {
                @include font(500, 28px !important, 33px !important);
                color: $white;
                display: flex;
                justify-content: center;
                position: absolute;
                width: 100%;
                bottom: 30px;
                z-index: 9;
                text-transform: none;
            }

            &:before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(180deg,
                        rgba(0, 0, 0, 0) 0%,
                        rgba(0, 0, 0, 0.8) 73.97%);
                z-index: 2;
            }

            &:hover {
                img {
                    transform: scale(1.02);
                }
            }
        }
    }
}

.section-empowerment-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px !important;

    h2 {
        margin-bottom: 0px !important;
    }
}

.empowermentTemplate {
    padding: 80px 5px 100px;

    &__title {
        font-size: 36px !important;
        line-height: 40px !important;
        font-weight: 600 !important;
        color: #2e475d;
        margin: 0 0 !important;
        text-align: center;
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 45px !important;
    }
}

.blogCard {
    width: 100%;
    height: 240px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    border: 3px solid #fff;
    transition: all 0.5s ease;

    &__title {
        position: absolute;
        left: 25px;
        bottom: 25px;
        padding: 0;
        margin: 0 !important;
        font-size: 22px !important;
        line-height: 26px !important;
        font-weight: 600 !important;
        color: #fff;
        z-index: 1;
    }

    &__image {
        height: 100%;
        width: 100%;
        position: relative;
        transition: all 0.5s ease;

        &::after {
            content: '';
            height: 80%;
            width: 100%;
            position: absolute;
            // background: rgb(0, 0, 0);
            // background: linear-gradient(0deg, rgba(0, 0, 0, 0.82) 12%, rgba(0, 0, 0, 0) 100%);
            bottom: 0;
            display: block;
        }
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &:hover {
        box-shadow: 0px 0px 22px -4px rgba(0, 0, 0, 0.28);

        .blogCard__image {
            transform: scale(1.1);
        }
    }
}

.blogDetailsPage {
    padding: 50px 5px 80px;


    .MuiBreadcrumbs-root {
        margin-bottom: 50px;
        background: #f5f5f5;
        padding: 15px 20px;
        border-radius: 5px;

        .MuiBreadcrumbs-separator {
            font-size: 28px;
            line-height: 22px;
            height: 24px;
            overflow: hidden;
            color: #63120d;
            margin: 0 15px;
        }

        .MuiBreadcrumbs-li {
            font-size: 15px;
            line-height: 24px;
            color: #2e475d;
            opacity: 80%;
            cursor: pointer;

            .MuiTypography-root {
                font-size: 15px;
                line-height: 24px;
                color: #2e475d;
                opacity: 80%;
            }
        }
    }
}

.blogDetailsContent {
    margin-top: 40px;

    &__title {
        font-size: 28px !important;
        line-height: 36px !important;
        font-weight: 600 !important;
        color: #2e475d;
        margin: 0 0 18px !important;
    }

    p {
        font-size: 16px;
        line-height: 24px;
        color: #2e475d;
        opacity: 80%;
    }

    &__info {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }

    &__auth {
        display: flex;
        align-items: center;
        margin: 10px 0;
        margin-right: 30px;

        img {
            height: 45px;
            width: 45px;
            border-radius: 50%;
            border: 2px solid #eee;
            overflow: hidden;
            object-fit: cover;
            margin-right: 10px;
            box-shadow: 0px 0px 22px -4px rgba(0, 0, 0, 0.28);
        }

        &-name {
            font-size: 14px;
            line-height: 24px;
            color: #2e475d;
            margin: 0;

            span {
                font-size: 14px;
                line-height: 24px;
                color: #63120d;
                margin: 0;
                font-weight: 500 !important;
            }
        }
    }

    &__category {
        padding: 3px 18px;
        font-size: 14px;
        font-weight: 500 !important;
        border: 2px solid #63120d;
        border-radius: 50px;
        background: #63120d;
        color: #fff;
        margin-bottom: 20px;
        display: inline-block;
    }

    &__dateTime {
        font-size: 14px;
        line-height: 24px;
        color: #2e475d;
        margin: 0;
        display: flex;
        align-items: center;

        svg {
            margin-right: 8px;
            color: #63120d;
            fill: #63120d;
        }
    }
}

.blogHeading {
    background: #f8f7f3;
    padding: 50px 15px;
    text-align: center;

    &__title {
        font-size: 36px !important;
        line-height: 40px !important;
        font-weight: 600 !important;
        color: #2e475d;
        margin: 0 0 15px !important;
    }

    .MuiBreadcrumbs-root {

        .MuiBreadcrumbs-ol {
            justify-content: center;
        }

        .MuiBreadcrumbs-separator {
            font-size: 28px;
            line-height: 22px;
            height: 24px;
            overflow: hidden;
            color: #63120d;
            margin: 0 15px;
        }

        .MuiBreadcrumbs-li {
            font-size: 15px;
            line-height: 24px;
            color: #2e475d;
            opacity: 80%;
            cursor: pointer;

            .MuiTypography-root {
                font-size: 15px;
                line-height: 24px;
                color: #2e475d;
                opacity: 80%;
            }
        }
    }
}

.categoryBlogPage {
    padding: 60px 5px 80px;
}

.blogBox {
    border: 1px solid #ddd;
    border-radius: 15px;
    margin-bottom: 30px;
    height: calc(100% - 30px);
    overflow: hidden;

    &__image {
        height: 208px !important;
        width: 100% !important;
        overflow: hidden;
        cursor: pointer;


        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__info {
        padding: 30px;
    }

    &__title {
        font-size: 22px !important;
        line-height: 32px !important;
        font-weight: 600 !important;
        color: #2e475d;
        margin: 0 0 18px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__text {
        font-size: 14px;
        line-height: 22px;
        color: #2e475d;
        opacity: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
    }



    &__date {
        font-size: 14px;
        line-height: 24px;
        color: #2e475d;
        margin: 0;
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        svg {
            height: 20px;
            width: 20px;
            margin-right: 8px;
            color: #63120d;
            fill: #63120d;
        }
    }

    &__link {
        font-weight: 700 !important;
        color: #63120d;
        font-size: 14px;
        line-height: 24px;
        position: relative;
        padding: 8px 0;
        cursor: pointer;
        transition: all 0.5s ease;

        svg {
            height: 22px;
            width: 22px;
            color: #63120d;
            fill: #63120d;
            margin-left: 6px;
            transition: all 0.2s ease;
        }

        &::after {
            content: '';
            height: 3px;
            width: 0;
            position: absolute;
            bottom: 0;
            background: #63120d;
            display: block;
            transition: all 0.5s ease;
        }
    }

    &__auth {
        font-size: 14px;
        line-height: 24px;
        color: #a0a6ab;
        margin: 0 0 10px;
        text-transform: uppercase;

        span {
            font-size: 14px;
            line-height: 24px;
            color: #63120d;
            font-weight: 500 !important;
        }
    }

    &:hover {
        box-shadow: 0px 0px 22px -4px rgba(0, 0, 0, 0.28);
        border-color: #fff;

        .blogBox__link {
            color: #2e475d;

            svg {
                margin-left: 12px;
            }

            &::after {
                content: '';
                height: 3px;
                width: 100%;
            }
        }
    }
}

/* Media query for empowerment */
// Large devices (desktops, 1200px and up)
@media (max-width: 1200px) {
    .section-empowerment {
        padding: 55px 0 0;

        .empower-list {

            div.col-6,
            div.col-sm-4,
            div.col-12,
            div.col-sm-8 {
                padding: 0 5px 0 0;
            }

            &.row {
                margin-right: 0;
                margin-left: 0;
            }

            .empower-list-item {
                margin-bottom: 5px;

                h3 {
                    font-size: 26px !important;
                    line-height: 28px !important;
                    bottom: 0;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .section-empowerment {
        padding: 24px 0 0;

        h2 {
            font-size: 30px !important;
            line-height: 35px !important;
            margin-bottom: 33px !important;
        }

        .empower-list {

            div.col-6,
            div.col-sm-4,
            div.col-12,
            div.col-sm-8 {
                padding: 0 5px 0 0;
            }

            &.row {
                margin-right: 0;
                margin-left: 0;
            }

            .empower-list-item {
                margin-bottom: 5px;

                h3 {
                    font-size: 16px !important;
                    line-height: 19px !important;
                    width: 100%;
                    bottom: -15px;
                }
            }
        }
    }

    .blogDetailsContent__title {
        font-size: 24px !important;
        line-height: 32px !important;
    }
}

.writeReviewBlock {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 70px 0 30px;

    &__img {
        height: 55px;
        width: 55px;
        border-radius: 8px;
        border: 1px solid #eee;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__form {
        width: calc(100% - 80px);
        display: flex;
        align-items: self-end;
        justify-content: space-between;
        gap: 20px;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #63120d;
            border: 1px solid #63120d;
            min-height: 40px;
            min-width: 200px;
            border-radius: 6px;
            color: #fff;
            text-transform: uppercase;
            text-decoration: none;
            padding: 5px 10px;
            font-weight: 500 !important;
            font-size: 16px;
            line-height: 19px;
        }
    }

    &__input {
        width: 100%;

        input,
        textarea {
            height: 55px;
            width: 100%;
            border: 1px solid #eee;
            border-radius: 8px;
            padding: 8px 15px;
            font-size: 14px;
            line-height: 26px;
        }

        input {
            // margin-bottom: 10px;
            height: 35px;
        }
    }
}

.commentTitle {
    font-size: 24px !important;
    line-height: 34px !important;
    font-weight: 600 !important;
    color: #2e475d;
    margin: 0 0 25px !important;
}

.commentCard {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;

    &__img {
        height: 55px;
        width: 55px;
        border-radius: 8px;
        border: 1px solid #eee;
        overflow: hidden;
        margin-right: -10px;
        position: relative;
        background: #eee;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__content {
        width: calc(100% - 48px);
    }

    &__email {
        padding: 5px 20px;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
        color: #2e475d;
        margin-bottom: 5px;
    }

    &__title {
        font-size: 16px !important;
        line-height: 20px !important;
        font-weight: 600 !important;
        color: #2e475d;
        margin: 0 0 8px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .deleteBtn {
            height: 25px;
            width: 25px;
            border: none;
            // background: #63120d21;
            border: 2px solid #63120d21;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                height: 18px;
                width: 18px;
                color: #63120d;
                fill: #63120d;
                path {
                    fill: #63120d; 
                }
            }
        }
    }

    &__text {
        padding: 15px;
        box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.20);
        background-color: #fff;
        border-radius: 8px;

        p {
            font-size: 14px;
            line-height: 23px;
            margin: 0;
            color: #2e475d;
            opacity: 80%;
        }

    }


}