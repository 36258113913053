@import "../../../assets/styles/_colors.scss";
@import "../../../assets/styles/_mixin.scss";

.wrapper-footer {
    background: #f8f8f9;

    .items-footer {
        @include flex-item(space-between, flex-start);
        padding: 30px 0;

        .card-items {
            ul {
                li {
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    a {
                        @include font(400, 16px, 18px);
                        color: rgba($black, 0.7);
                    }
                }

                .btn-show-more {
                    font-size: 16px;
                    margin-top: 15px;
                }
            }

            &.senior-living {
                min-width: 320px;

                @media (max-width: 767px) {
                    min-width: 0;
                }
            }

            &.home-share {
                min-width: 265px;

                @media (max-width: 767px) {
                    min-width: 0;
                }
            }

            &.social-media-items {
                ul li a {
                    display: inline;

                    img {
                        width: 18px;
                        margin-right: 10px;
                        display: inline;
                    }
                }
            }
        }
    }
}

.wrapper-copyrights {
    background: $black;

    .section-copyrights {
        width: 100%;

        .section-right {
            @include flex-d(flex-start, center, row);
            width: 100%;
            min-height: 50px;

            p {
                @include font(500, 16px, 18px);
                color: $white;
                margin: 0 20px 0 0;
            }

            .btn-theme {
                color: $white;

                img {
                    margin: 1px 10px 0 0;
                }
            }
        }
    }
}

.social-media-items {
    margin-top: 15px;

    ul li a {
        display: inline;

        img {
            width: 18px;
            margin-right: 10px;
            display: inline;
        }
    }
}

/* Media query for footer */
// Large devices (desktops, 1200px and up)
@media (max-width: 1023px) {
    .wrapper-copyrights {
        .section-copyrights {
            .section-right {
                width: auto;

                p {
                    margin-right: 50px;
                }
            }
        }
    }

    .wrapper-footer {
        .items-footer {
            flex-wrap: wrap;

            .card-items {
                width: 50%;
                padding-right: 25px;

                &:nth-child(-n + 2) {
                    margin-bottom: 30px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .wrapper-footer {
        .items-footer {
            padding: 0 0 25px;
            width: 100%;
            flex-direction: column;

            .card-items {
                width: 100%;
                padding: 0;

                h4 {
                    font-size: 16px !important;
                    line-height: 19px !important;
                    padding: 24px 0 0 0;
                    margin: 0 0 10px !important;
                }

                ul {
                    li {
                        font-size: 14px;
                        margin-bottom: 10px;
                    }
                }

                &:not(:last-child) {
                    margin-bottom: 30px;
                }
            }
        }
    }

    .wrapper-copyrights {
        .section-copyrights {
            padding: 15px 0;

            .section-right {
                width: 100%;
                flex-direction: column;

                .btn-theme {
                    font-weight: 500 !important;
                    font-size: 16px;
                }

                p {
                    padding-top: 10px;
                    margin: 0;
                }
            }
        }
    }
}

.largeContainer {
    max-width: 1600px;
    margin: 0 auto;
}

.inputGroup {
    margin-bottom: 15px;
}
.customError {
    font-size: 12px;
    color: #63120d;
    margin: 6px 0 0;
}

.siteFooter {
    background-color: #2e475d;
    -webkit-clip-path: polygon(0 3rem, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 3rem, 100% 0, 100% 100%, 0 100%);
    padding: 100px 25px 40px;

    h4 {
        font-size: 15px !important;
        line-height: 22px !important;
        color: #fff;
        font-weight: 700 !important;
        margin-bottom: 15px !important;
    }

    &__links {
        display: flex;
        flex-wrap: wrap;
        padding: 0 !important;
        margin: 42px 0 30px !important;
        list-style-type: none;

        li a {
            font-size: 14px !important;
            line-height: 22px !important;
            color: #fff !important;
            margin-bottom: 10px !important;
            display: block;
            margin: 0 18px 12px 0;

            &:hover {
                text-decoration: underline !important;
            }
        }
    }

    &__menu {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;
    }

    &__menuBox {

        ul {
            margin: 0;
            padding: 0;
            margin-bottom: 40px !important;

            li {
                a {
                    font-size: 14px !important;
                    line-height: 22px !important;
                    color: #fff;
                    display: block;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }

                font-size: 14px !important;
                line-height: 22px !important;
                color: #fff;
                margin-bottom: 10px !important;
                display: block;
                max-width: fit-content;
            }
        }
    }

    &__socialIcons {
        margin-bottom: 30px;

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;

            li a {

                height: 32px;
                width: 32px;
                margin: 0 12px 12px 0;
                background-color: #fff;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                svg,
                img {
                    height: 24px;
                    width: 24px;
                    color: #121417;
                    fill: #121417;
                }

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
    }

    &__copyRight p {
        font-size: 14px !important;
        line-height: 22px !important;
        color: #fff;
        margin-bottom: 10px !important;
        display: block;
    }
}

.teamPage {
    padding: 70px 20px;

    &__page {
        font-size: 32px !important;
        line-height: 36px !important;
        font-weight: 900 !important;
        margin: 0 0 55px !important;
        text-align: center;
    }
}

.teamMemberModule {
    margin: 60px 0 30px;

    &__title {
        font-size: 36px !important;
        line-height: 42px !important;
        font-weight: 900 !important;
        margin: 0 0 40px !important;
        text-align: center;
    }
}

.teamSections {
    margin-bottom: 60px;

    &__title {
        font-size: 24px !important;
        line-height: 30px !important;
        font-weight: 700 !important;
        margin: 0 0 40px !important;
    }

    &-list {
        padding: 15px;
        border: 2px solid #f5f5f5;
        border-radius: 15px;
        display: flex;
        align-items: center;

        &__img {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 60px;
            width: 60px;
            border-radius: 15px;
            height: 60px;
            margin-right: 15px;
            overflow: hidden;
            box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.20);

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        &__name {
            font-size: 20px !important;
            line-height: 28px !important;
            font-weight: 700 !important;
            margin: 0 0 5px !important;
            color: #000 !important;
        }

        &__status {
            color: #63120d !important;
            font-weight: 700 !important;
            margin: 0 0 !important;
        }
    }
}

.founderBlock {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px;
    border: 2px solid #f5f5f5;
    border-radius: 15px;

    &__img {
        max-width: 320px;
        width: 100%;
        border-radius: 15px;
        height: 320px;
        overflow: hidden;
        box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.20);
        border: 3px solid #fff;
        margin-right: 50px;
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 320px;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__title {
        font-size: 32px !important;
        line-height: 36px !important;
        font-weight: 600 !important;
        margin: 0 0 22px !important;
        color: #000 !important;
    }

    &__subTitle {
        color: #63120d !important;
        font-weight: 700 !important;
        margin: 0 0 15px !important;
    }

    &__info {
        p {
            font-size: 14px;
            line-height: 18px;
            color: #999;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            line-clamp: 5;
            -webkit-box-orient: vertical;
        }
    }

    &__socialLink {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 25px;

        a {
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            border: 1px solid #ddd;
            background: #fff;
            transition: all 0.2s ease;

            svg {
                height: 20px;
                width: 20px;
                color: #63120d !important;
                transition: all 0.2s ease;
            }

            &:hover {
                border: 1px solid #63120d;
                background: #63120d;

                svg {
                    fill: #fff !important;
                }
            }
        }
    }
}

.teamMember {
    display: flex;
    align-items: center;
    margin: 20px 0;

    &__img {
        height: 175px;
        width: 175px;
        margin-right: 25px;
        border-radius: 50%;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 175px;
        overflow: hidden;
        box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.20);
        border: 3px solid #fff;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__name {
        font-size: 24px !important;
        line-height: 28px !important;
        font-weight: 500 !important;
        margin: 0 0 15px !important;
        color: #000 !important;
    }

    &__position {
        color: #63120d !important;
        font-weight: 700 !important;
        margin: 0 0 15px !important;
    }

    &__info {
        p {
            font-size: 14px;
            line-height: 18px;
            color: #999;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    &__socialLink {
        display: flex;
        align-items: center;
        gap: 10px;

        a {
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            border: 1px solid #ddd;
            background: #fff;
            transition: all 0.2s ease;

            svg {
                height: 20px;
                width: 20px;
                color: #63120d !important;
                transition: all 0.2s ease;
            }

            &:hover {
                border: 1px solid #63120d;
                background: #63120d;

                svg {
                    fill: #fff !important;
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .founderBlock {
        padding: 15px;

        &__img {
            max-width: 270px;
            height: 270px;
            margin-right: 22px;
            flex-basis: 270px;
        }

        &__title {
            font-size: 28px !important;
        }
    }
}

@media (max-width: 660px) {
    .siteFooter__menu {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .founderBlock {
        display: block;

        &__img {
            margin: 0 auto 30px;
        }
    }
}

@media (max-width: 540px) {
    .teamMember {
        display: block;
        text-align: center;

        &__img {
            margin: 0 auto 10px;
        }

        &__socialLink {
            justify-content: center;
        }
    }
}