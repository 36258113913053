@import '../../../../assets/styles/_colors.scss';
@import '../../../../assets/styles/_mixin.scss';

.slick-slider {
    @include flex-item(space-between, center);
    .slick-dots {
        display: none !important;
    }
    .slick-list {
        max-width: calc(100% - 50px);
        max-height: 64px;
        .slick-track {
            width: 110px !important;
        }
        .slick-slide {
            width: 110px !important;
            margin-right: 10px;
            background-color: #000;
            img {
                border-radius: 2px;
            }
        }
    }
    .slick-arrow {
        position: static;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.9) 99.99%, rgba(255, 255, 255, 0) 100%);
        border-radius: 4px;
        width: 35px;
        height: 63px;
        transform: translate(0);
        &.slick-prev {
            display: none !important;
        }
        &.slick-next {
            &::after {
                position: absolute;
                content: "";
                width: 10px;
                height: 20px;
                top: 50%;
                left: 50%;
                margin-left: -5px;
                margin-top: -10px;
                background-image: url("../../../../assets/images/arrow-right-white.svg");
            }
            &::before {
                display: none;
            }
        }
    }
}

.slider-rooms {
    display: flex;
    width: 100%;
    overflow-x: auto;
    img {
        width: 110px;
        height: 110px;
        margin-left: 15px;
        &:first-child {
            margin-left: 0;
        }
    }
}