.section-title {
  @include flex-item(space-between, center);
  margin-bottom: 25px;
  h2 {
    margin-bottom: 0 !important;
    @media (max-width: 767px) {
      font-size: 18px !important;
      line-height: 21px !important;
    }
  }
  a,
  button {
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 14px;
      min-height: 30px;
      min-width: 120px !important;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
}

.container-senior-living {
  .section-cards-care {
    // height: calc(100vh - 30px - 66px - 65px - 55px - 50px);
    // overflow-y: auto;
    // &.section-cards-care-without-pagination {
    //   height: calc(100vh - 30px - 66px - 65px - 50px);
    // }
    &.section-no-care-message {
      @include flex-item(center, center);
      span {
        @include font(500, 25px, 28px);
        // color: $main-btn-color;
      }
    }
    @media (max-width: 767px) {
      height: auto;
      &.section-cards-care-without-pagination {
        height: auto;
      }
    }
  }
  .care-details-card {
    @include flex-item(space-between, flex-start);
    background: $white;
    border: 1px solid $border-light;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .card-left {
      @include flex-item(flex-start, center);
      .img-home {
        width: 160px;
        height: auto;
        border-radius: 4px;
        object-fit: cover;
        @media (max-width: 1023px) {
          width: 120px;
        }
      }
      .details-brief {
        padding: 0 20px;
        li {
          margin-bottom: 20px;
          list-style: none !important;
          &:last-child {
            margin-bottom: 0;
          }
          span {
            @include font(400, 12px, 14px);
            color: rgba($black, 0.5);
            padding-bottom: 4px;
            display: block;
          }
          strong {
            @include font(500, 16px, 19px);
            display: block;
            a {
              color: $main-btn-color;
            }
            &.status-pending,
            &.status-in-progress,
            &.status-draft {
              color: $status-pending;
            }
            &.status-active,
            &.status-approved {
              color: $status-active;
            }
            &.status-reject,
            &.status-rejected,
            &.status-cancelled {
              color: $text-erorr;
            }
          }
        }
      }
      @media (max-width: 700px) {
        flex-direction: column;
        align-items: flex-start;
        padding-right: 20px;
        .img-home {
          margin-bottom: 20px;
        }
        .details-brief {
          padding: 0;
        }
      }
    }
    .card-right {
      @include flex-d(flex-start, flex-start, column);
      a {
        &:first-child {
          margin-bottom: 15px;
        }
      }
    }
    @media (max-width: 500px) {
      flex-direction: column;
      padding: 10px;
      .card-left {
        padding: 0 0 20px;
        .details-brief li {
          margin-bottom: 12px;
          span {
            padding-bottom: 2px;
          }
          strong {
            font-size: 14px;
            line-height: 16px;
            a {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
      .card-right {
        flex-direction: row;
        button,
        a {
          &:not(:last-child) {
            margin: 0 20px 0 0;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .section-title {
    margin-bottom: 20px;
  }
}
