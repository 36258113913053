.wrapper-view-room {
    .back-button-container {
        display: flex;
        justify-content: flex-end;
        padding: 20px 0;

        @media (max-width: 767px) {
            padding: 10px 0;

            button {
                font-size: 16px;
            }
        }
    }

    .section-video-images {
        @include flex-item(space-between, flex-start);

        .container-video {
            width: calc(100% - 250px - 15px);
            border-radius: 4px;
        }

        .container-images {
            display: flex;
            flex-direction: column;
            width: 250px;
            height: 450px;
            overflow: hidden;

            >div {
                position: relative;
                margin-bottom: 10px;

                img {
                    border-radius: 4px;
                    height: 145px;
                }

                &:last-child {
                    margin-bottom: 0;

                    &:nth-of-type(3).more-than-three {
                        &:before {
                            @include positioned(0, 0);
                            width: 100%;
                            height: 100%;
                            background-color: rgba($black, 0.6);
                            border-radius: 4px;
                        }
                    }
                }

                .btn-theme {
                    @include center(both);
                    color: $white;
                    text-transform: uppercase;
                }
            }
        }

        @media (max-width: 767px) {
            flex-direction: column;

            .container-video {
                width: 100%;
                margin-bottom: 25px;
            }

            .container-images {
                width: 100%;
                height: 130px;
                flex-direction: row;

                >div {
                    margin: 0 15px 0 0;
                    width: calc((100% - 30px) / 3);

                    &:last-child {
                        margin: 0;
                    }

                    .btn-theme {
                        font-size: 15px;
                    }
                }
            }
        }

        @media (max-width: 500px) {
            .container-images {
                flex-direction: column;

                >div {
                    width: 100%;
                    margin: 0 0 20px;
                }
            }
        }
    }

    .section-title-info {
        padding: 30px 0 20px;

        .title-and-social {
            @include flex-item(space-between, center);
            margin-bottom: 12px;

            @media (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
            }

            h1 {
                display: flex;
                align-items: center;
                margin-bottom: 0 !important;
                max-width: calc(100% - 280px);

                span {
                    @include flex-item(center, center);
                    @include font(500, 12px, 14px);
                    color: $main-btn-color;
                    min-width: 120px;
                    min-height: 32px;
                    padding: 5px 10px;
                    background-color: rgba($main-link-color, 0.1);
                    text-transform: uppercase;
                    border-radius: 2px;
                    margin-left: 10px;
                }

                @media (max-width: 1023px) {
                    flex-direction: column;
                    align-items: flex-start;

                    span {
                        margin: 10px 0 0;
                    }
                }

                @media (max-width: 767px) {
                    font-size: 25px !important;
                    max-width: 100%;
                }
            }

            .socail-buttons {
                @include flex-item(flex-start, center);

                .social-icons {
                    display: flex;
                }

                button,
                a {
                    margin-right: 20px;

                    &:last-child {
                        margin-right: 0;
                    }

                    &.btn-add {
                        min-width: 150px;
                    }
                }

                .btn-remove-add {
                    margin-left: 20px;
                }

                @media (max-width: 767px) {
                    margin-top: 20px;
                }
            }
        }

        div.labels {
            margin-bottom: 12px;

            span {
                @include font(500, 14px, 16px);
                color: rgba($black, 0.5);
                text-transform: uppercase;
                @include small-dot;

                &:last-child {
                    margin-right: 0;

                    &:after {
                        display: none;
                    }
                }
            }
        }

        .address-info {
            span {
                @include font(400, 16px, 19px);
                color: $black;
                @include small-dot;

                &:last-child {
                    margin-right: 0;

                    &:after {
                        display: none;
                    }
                }
            }

            a,
            button {
                @include font(500, 16px, 19px);
                color: $main-btn-color;

                @media (max-width: 500px) {
                    display: flex;
                }
            }
        }
    }

    .section-static-content {
        margin-bottom: 30px;

        p {
            @include font(400, 16px, 22px);
            color: $black;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .section-room-details {
        padding: 35px 0 60px;
        border-bottom: 1px solid rgba($black, 0.1);

        @media (max-width: 767px) {
            padding: 30px 0;
        }
    }
}

.room-details {
    border: 1px solid rgba($black, 0.15);
    border-radius: 4px;
    padding: 20px;
    display: flex;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    @media (max-width: 650px) {
        flex-direction: column;
        padding: 15px;
    }

    .img-container {
        width: 210px;
        position: relative;

        img:not(.arrow) {
            border-radius: 4px;
        }

        &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            // background: linear-gradient(270deg, rgba(0, 0, 0, 0.9) 0.16%, rgba(255, 255, 255, 0) 53.72%);
            border-radius: 4px;
        }

        &.section-no-images {
            &::after {
                display: none;
            }
        }

        @media (max-width: 650px) {
            width: 250px;
        }
    }

    .details-brief {
        display: flex;
        flex-direction: column;
        width: calc(100% - 210px - 150px);
        padding: 0 20px;

        h2 {
            @include font(700, 22px !important, 26px !important);
            color: $main-btn-color;
            margin-bottom: 15px !important;
            word-break: break-word;
        }

        .room-type {
            display: flex;
            margin-bottom: 18px;

            span,
            a {
                @include font(400, 14px, 16px);
                color: $black;
                @include small-dot;

                &:last-child {
                    margin-right: 0;

                    &::after {
                        display: none;
                    }
                }
            }

            @media (max-width: 850px) {
                flex-direction: column;

                span,
                a {
                    &::after {
                        display: none;
                    }
                }
            }

            @media (max-width: 650px) {
                flex-direction: row;
                margin-bottom: 0;

                span:not(:last-child),
                a:not(:last-child) {
                    margin-right: 16px !important;

                    &::after {
                        display: block !important;
                    }
                }
            }

            @media (max-width: 500px) {
                flex-direction: column;

                span:not(:last-child),
                a:not(:last-child) {
                    margin-right: 0 !important;

                    &::after {
                        display: none !important;
                    }
                }
            }
        }

        .section-facilities {
            padding: 0;
            border: none;
        }

        @media (max-width: 650px) {
            width: 100%;
            padding: 15px 0;
        }
    }

    .details-fee {
        @include flex-d(space-between, flex-start, column);
        width: 150px;

        .price {
            @include font(500, 18px, 21px);
            color: $black;

            span {
                display: block;
                font-weight: 400;
                font-size: 14px;
                color: rgba($black, 0.5);
            }
        }

        button,
        a:not(.icon) {
            @include font(500, 14px, 16px);
            min-width: 150px;
            min-height: 32px;
            border-radius: 4px;
            text-transform: capitalize;
        }

        @media (max-width: 650px) {
            flex-direction: row;
            width: 100%;

            button,
            a:not(.icon) {
                min-width: 120px;
                font-size: 12px;
            }
        }
    }
}

.section-facilities {
    padding: 30px 0;
    border-top: 1px solid rgba($black, 0.1);
    border-bottom: 1px solid rgba($black, 0.1);

    ul {
        display: flex;

        li {
            @include flex-item(flex-start, center);
            margin-right: 20px;
            padding: 2px 5px;
            border-radius: 4px;
            border: 1px solid $white;

            span {
                @include font(500, 16px, 19px);
                color: $black;
                margin-left: 3px;
            }

            &:last-child {
                margin-right: 0;
            }

            &.available {
                span {
                    color: $main-btn-color;
                }
            }
        }
    }

    @media (max-width: 500px) {
        padding: 15px 0;

        ul {
            flex-wrap: wrap;

            li {
                width: calc(50% - 10px);

                span {
                    @include font(500, 14px, 16px);
                }

                &:nth-child(even) {
                    margin-right: 0;
                }

                &:nth-child(-n + 2) {
                    margin-bottom: 15px;
                }
            }
        }
    }
}

.modal-dialog.modal-more-images {
    @include flex-item(center, center);
    height: 100vh;
    max-width: 100% !important;
    height: 100vh;
    margin: 0 !important;

    .modal-content {
        max-height: calc(100vh - 80px);
        overflow-y: auto;
        width: auto;
        max-width: 80%;
        padding: 50px;
    }

    .carousel.carousel-slider {
        width: 500px !important;
        margin: 0 auto;

        .slide {
            background-color: $grey;

            >div {
                @include flex-item(center, center);
                height: 100%;
            }
        }

        .control-arrow {
            @include center(verticle);
            bottom: auto;
            opacity: 1;
            background: rgba($black, 0.9);
            padding: 1px 5px 5px 5px;
        }

        .carousel-status {
            display: none;
        }

        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    @media (max-width: 767px) {
        .modal-content {
            padding: 40px 20px;

            .carousel.carousel-slider {
                .control-arrow {
                    font-size: 15px;
                    padding: 1px 2px;

                    &:before {
                        border-width: 5px;
                    }
                }
            }
        }
    }
}

.section-no-images {
    @include flex-item(center, center);
    text-align: center;
    min-height: 100px;
    width: 100%;
    background: rgba(#c4c4c4, 0.5);
    padding: 10px;

    p {
        @include font(500, 16px, 19px);
        color: $black;
        margin-bottom: 0;
    }

    @media (max-width: 767px) {
        min-height: 80px;
    }

    &.section-no-video {
        width: calc(100% - 265px);
        height: 450px;

        @media (max-width: 767px) {
            height: 220px;
            width: 100%;
            margin-bottom: 25px;
        }
    }

    &.section-no-video-room-details {
        margin-bottom: 20px;
        min-height: 200px;

        @media (max-width: 767px) {
            min-height: 100px;
        }
    }
}

.video-react {
    border-radius: 4px;

    .video-react-big-play-button {
        @include center(both);
    }

    .video-react-video,
    .video-react-poster {
        border-radius: 4px;
    }
}

.section-availability-filters {
    padding-top: 30px;

    h2 {
        margin-bottom: 20px !important;
    }

    .form-filters {
        @include flex-item(space-between, flex-start);

        .btn-theme {
            min-height: 56px;
            text-transform: capitalize;
            min-width: 250px;
        }

        .select-guest {
            margin-bottom: 0 !important;
            width: 350px;

            .select-dropdown__control {
                min-height: 56px;
                padding-left: 25px;
                background: url("../../assets/images/icon-guests.svg") no-repeat 7px 18px;
            }
        }

        .calendar-input-holder-care {
            width: calc(100% - 350px - 250px - 40px);

            .form-group {
                margin-bottom: 0 !important;
            }
        }

        .react-daterange-picker {
            .react-daterange-picker__wrapper {
                min-height: 56px;
                padding-left: 42px;
                min-width: 255px;
            }

            .react-daterange-picker__calendar-button {
                right: auto;
                left: 10px;
            }
        }

        @media (max-width: 1180px) {
            .btn-theme {
                min-width: 180px;
            }

            .select-guest {
                width: 20%;
            }

            .calendar-input-holder-care {
                width: calc(80% - 180px - 40px);
            }
        }
    }
}

@media (max-width: 767px) {
    .section-facilities {
        ul {
            flex-wrap: wrap;
            justify-content: center;

            li {
                span {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }

    .room-details {
        .details-fee {
            flex-direction: row;
            width: 100%;
        }
    }

    .section-availability-filters .form-filters {
        flex-direction: column;

        .calendar-input-holder-care,
        .select-guest {
            width: 100%;
            margin-bottom: 20px !important;
        }
    }
}

.video-container,
.container-video {
    .video-react.video-react-fluid {
        padding: 0 !important;
        height: 450px;

        @media (max-width: 767px) {
            height: 220px;
        }
    }
}

.product-details {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 50px;

    &__left {
        width: 20%;
        padding-right: 20px;

        .ratingBox {
            margin-top: 0;
        }
    }

    &__title {
        font-size: 1.5rem !important;
        line-height: 1.25 !important;
        font-weight: 400 !important;
    }

    &__link {
        display: inline-block;
        margin-bottom: 1rem;
        cursor: pointer;
        font-weight: 700 !important;
        color: #f25621;
        background-color: transparent;
        border: none;
        font-size: 14px;
    }

    &__contactList {
        border-top: 0.0625rem dotted #dddfe4;
        border-bottom: 0.0625rem dotted #dddfe4;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        padding: 20px 0;
        list-style-type: none;

        li {
            margin-bottom: 18px;
            font-size: 14px;
            color: #31363f;

            svg {
                height: 20px;
                width: 20px;
                margin-right: 10px;
                color: #00e0ce;
                fill: #00e0ce;
            }
        }
    }

    &__linkBox {
        padding: 1rem 0;
        margin: 1rem 0;
        border-top: 1px dotted #a9afbc;

        .product-details__link {
            font-weight: 400 !important;

            svg {
                color: #f25621;
                fill: #f25621;
                height: 18px;
                width: 18px;
                margin-right: 7px;
            }
        }
    }

    &__right {
        width: 80%;

        p {
            font-size: 14px;
        }
    }
}

.viewCareTabs {
    border-top: 0.0625rem solid #dddfe4;
    border-bottom: 0.0625rem solid #dddfe4;
    background-color: #f1f2f4;
    margin-top: 1rem;
    margin-bottom: 2rem;
    overflow-x: auto;

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        align-items: center;

        a {
            padding: 15px 0;
            display: inline-block;
            cursor: pointer;

            span {
                border-right: 1px solid #dddfe4;
                padding: 0 18px;
                color: #31363f;
            }

            &:hover {
                box-shadow: inset 0 -0.25rem 0 #a9afbc;
            }
        }
    }
}

.facility {
    &__title {
        color: #000;
        font-size: 22px;
        font-weight: 900;
        line-height: 26px;
        position: relative;
        display: flex;
        flex-direction: column;

        &::after {
            background: linear-gradient(90deg, #fad6a5, #ebb05f);
            background: linear-gradient(90deg, #e54b29, #64130e);
            border-radius: 16px;
            bottom: -8px;
            content: "";
            display: inline-block;
            height: 4px;
            left: 0;
            position: absolute;
            width: 90px;
        }
    }

    &__header {
        align-items: center;
        border-bottom: 1px solid hsla(0, 0%, 73%, .5);
        display: flex;
        margin-bottom: 20px;
        margin-top: 30px;
        padding-bottom: 22px;

        p {
            align-items: center;
            display: flex;
            flex: 1;
            margin: 0;
        }

        &--iconWrapper {
            align-items: center;
            border: 1px solid #aad9ff;
            border-radius: 50%;
            display: flex;
            height: 48px;
            justify-content: center;
            width: 48px;
            background: rgba(99, 18, 13, 0.06);
            border-color: #63120d;
            margin-right: 12px;

            img {
                max-width: 30px;
            }
        }

        &-title {
            font-size: 16px;
            line-height: 16px;
            font-weight: 700;
        }
    }
}

.facilityList {
    display: grid;
    gap: 10;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 60px;

    &__item {
        &-title {
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 10px;
            font-weight: 700 !important;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
        }

        &-text {
            line-height: 22px !important;
            font-size: 14px;
            margin-bottom: 3px;

            span {
                line-height: 22px !important;
                font-size: 14px;
                margin-bottom: 3px;
            }
        }
    }
}

.reviewModule {
    margin-bottom: 70px;

    &__title {
        color: #000;
        font-size: 22px !important;
        font-weight: 900 !important;
        line-height: 26px !important;
        position: relative;
        display: flex;
        flex-direction: column;

        &::after {
            background: linear-gradient(90deg, #fad6a5, #ebb05f);
            background: linear-gradient(90deg, #e54b29, #64130e);
            border-radius: 16px;
            bottom: -8px;
            content: "";
            display: inline-block;
            height: 4px;
            left: 0;
            position: absolute;
            width: 90px;
        }
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;

        &-title {
            color: #31363f;
            font-size: 14px;
        }

        .MuiBox-root {
            margin-top: 5px;
            position: relative;
        }

        .MuiLinearProgress-root {
            height: 8px;

            .MuiLinearProgress-bar {
                background-color: #68c260;
            }
        }

        &-rate {
            color: #63120d;
            font-size: 15px;
            font-weight: 700 !important;
            position: absolute;
            right: 0;
            top: -22px;
        }
    }
}

.availabilityModule {
    margin: 60px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
}

.availabilityBox {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 12px;

    &__title {
        color: #000;
        font-size: 22px !important;
        font-weight: 600 !important;
        line-height: 26px !important;
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 0 15px !important;
    }
}

.availabilityCard {
    display: flex;
    background-color: #f9f9f9;
    padding: 12px;
    margin-top: 12px;
    position: relative;
    border-radius: 10px;
    border: 1px solid #f5f5f5;

    &__image {
        width: 125px;
        height: 125px;
        object-fit: cover;
        border: 1px solid #ddd;
        background-color: #eee;
        margin-right: 15px;
        border-radius: 5px;
        overflow: hidden;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 125px;
    }

    &__title {
        font-size: 14px !important;
        font-weight: 600 !important;
        margin: 0 0 8px !important;
        line-height: 20px !important;
    }

    &__info {
        p {
            font-size: 12px;
            color: #999;
            line-height: 16px;
            margin: 0 0 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    &__greenChip {
        font-size: 13px !important;
        font-weight: 600 !important;
        margin: 0 0 8px !important;
        line-height: 20px !important;
        background: #dbe9db;
        border: 1px solid green;
        padding: 3px 12px;
        min-height: 26px;
        display: inline-block;
        border-radius: 5px;
    }

    &__price {
        font-size: 18px;
        color: #63120d;
        font-weight: 700 !important;
    }

    &__discount {
        position: absolute;
        left: 13px;
        background: #63120d;
        top: 13px;
        font-size: 13px;
        padding: 6px 10px;
        font-weight: 700 !important;
        color: #fff;
        border-radius: 0 0 8px 0;
        margin: 0;
    }
}

.reviewDrawer {
    &__header {
        display: flex;
        padding: 16px 25px;
        align-items: center;
        border-bottom: 0.0625rem solid #f1f2f4;
    }

    &__backBtn {
        height: 22px;
        width: 22px;
        margin-right: 10px;

        svg {
            height: 22px;
            width: 22px;
        }
    }

    &__title {
        font-size: 1.25rem !important;
        letter-spacing: -.1px;
        line-height: 1.3 !important;
        color: #31363f;
        margin: 0 !important;
    }

    &__body {
        padding: 25px;

        .reviewModule {
            margin-top: 40px;
        }
    }
}

.review-smallCard {
    display: flex;
    align-items: center;
    background-color: #ebfffd;
    border-radius: 0.25rem;
    color: #31363f;
    margin-bottom: 35px;
    padding: 12px 15px;

    svg {
        color: #e2c42c;
        font-size: 1.4rem;
        height: 30px;
        width: 30px;
        fill: #e2c42c;
        margin-right: 15px;
    }

    &__text {
        b {
            color: #000;
        }
    }
}