@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_mixin.scss';

.container-values {
    padding-bottom: 100px;
    @media (max-width: 767px) {
        padding-bottom: 50px !important;
    }
    .tab-pane {
        max-width: 90%;
        margin: 0 auto;
        @media (max-width: 767px) {
            max-width: 100%;
        }
    }
    .tab-content {
        h3.title {
            @include font(700, 24px, 28px);
            color: $black;
            margin-bottom: 15px !important;
        }
        .description {
            margin-bottom: 0;
            p, ol li {
                @include font(400, 18px, 28px);
                color: $black;
                @media (max-width: 767px) {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
            div {
                margin-bottom: 25px;
                h4 {
                    margin-bottom: 10px !important;
                }
                ol {
                    padding: 0 0 0 20px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.section-values.nav-tabs {
    > li {
        padding: 24px 50px;
        border: 1px solid #CCCCCC;
        min-width: 260px;
    }
    @include flex-item(space-between, flex-start);
    padding: 65px 0 0;
    border-bottom: 0;
    @media (max-width: 767px) {
        padding: 30px 0;
    }
    .nav-item {
        margin-bottom: 0;
        .nav-link {
            border: none;
            padding: 0 0 5px;
            cursor: pointer;
            border-bottom: 1px solid $white;
            &.active {
                border-bottom-color: rgba($grey, 0.5);
            }
        }
        @media (max-width: 767px) {
            width: 25%;
            padding: 0 15px;
        }
        @media (max-width: 600px) {
            width: 50%;
            margin-bottom: 30px;
        }
        @media (max-width: 370px) {
            width: 100%;
        }
    }
    .card-values {
        @include flex-d(center, center, column);
        .img-icon {
            margin-bottom: 20px;
            height: 60px;
        }
        h4 {
            margin-bottom: 10px !important;
            text-align: center;
        }
        a, button {
            @include font(500, 16px, 19px);
            text-transform: none;
            img {
                margin: 2px 0 0 4px;
            }
        }
        .arrow {
            display: inline;
        }
        .learnMore {
            color: #63120d;
        }
        
    }
}
