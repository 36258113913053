.section-facilities-list {
    padding: 30px 0;
    border-bottom: 1px solid rgba($black, 0.1);
    > h2 {
        font-weight: 700 !important;
        word-break: break-word;
        @media (max-width: 767px) {
            font-size: 22px !important;
        }
    }
    .list-items {
        display: flex;
        flex-wrap: wrap;
        .list {
            width: 25%;
            padding-right: 15px;
            margin-bottom: 35px;
            &:last-child {
                padding-right: 0;
                margin-bottom: 0;
            }
            h2 {
                margin-bottom: 15px !important;
                @media (max-width: 767px) {
                    font-size: 20px !important;
                }
            }
            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                li {
                    @include font(500, 16px, 19px);
                    color: $black;
                    padding-left: 30px;
                    margin-bottom: 15px;
                    background: url(../../../assets/images/check.svg) no-repeat left top 3px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            @media (max-width: 1023px) {
                width: 50%;
            }
            @media (max-width: 500px) {
                width: 100%;
            }
        }
    }
    &.list-care-details {
        .list {
            width: 100%;
            margin: 0;
            h2 {
                display: none;
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    width: 25%;
                    @media (max-width: 767px) {
                        width: 50%;
                    }
                    @media (max-width: 500px) {
                        width: 100%;
                    }
                }
            }
        }
    }
    .list-staff {
        .btn-theme {
            @include font(500, 14px, 16px); 
            min-width: 150px;
            min-height: 32px;
            border-radius: 4px;
            text-transform: capitalize;
        }
    }
}

.list-staff {
    li {
        margin-bottom: 15px;
        * {
            color: $black;
        }
        strong {
            font-weight: 500 !important; 
            margin-right: 2px;
        }
    }
}

.modal-mini.modal-dialog.modal-staff-details {
    max-width: 600px !important;
    .modal-header {
        margin-bottom: 30px;
    }
    .modal-content {
        .list-staff {
            li:last-child {
                margin-bottom: 0;
            }
        }
        @media (max-width: 500px) {
            padding: 40px 20px;
        }
    }
}