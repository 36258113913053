.pagination {
  justify-content: center;
  .rc-pagination {
    margin: 15px 0 0;
    li {
      @include flex-item(center, center);
      min-width: 38px;
      height: 40px;
      border-color: rgba($main-btn-color, 0.5);
      background: $white;
      border-radius: 6px;
      margin-right: 18px;
      a {
        @include font(500, 16px, 19px);
        color: $black;
      }
      &.rc-pagination-prev,
      &.rc-pagination-next {
        width: 50px;
        margin-right: 20px;
        position: relative;
        a:after {
          content: "";
          width: 7px;
          height: 12px;
          opacity: 0.5;
          background: url(../../../assets/images/arrow-left.svg);
          background-size: 100%;
          margin-top: 0;
        }
        &:hover {
          a {
            opacity: 0.4;
          }
        }
      }
      &.rc-pagination-next {
        margin: 0;
        a:after {
          background: url(../../../assets/images/arrow-right.svg);
          background-size: 100%;
        }
      }
      &.rc-pagination-jump-next {
        width: 32px;
        position: relative;
        a {
          padding: 0;
        }
        &::after {
          width: 100%;
        }
      }
      &:hover,
      &:active,
      &.active,
      &.rc-pagination-item-active {
        a {
          opacity: 0.2;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .pagination {
    padding-left: 0;
    .rc-pagination {
      li {
        min-width: 35px;
        height: 35px;
        margin-right: 15px;
        a {
          @include font(500, 14px, 18px);
        }
        &.rc-pagination-prev,
        &.rc-pagination-next {
          width: 35px;
          margin-right: 15px;
        }
      }
    }
  }
}
