.container-add-new-care {
    a.btn-next, button.btn-next {
        &.disabled {
            pointer-events: none !important;
            opacity: 0.5;
        }
    }
    .section-title:not(.section-title-secondary) {
        @media (max-width: 767px) {
            display: none;
        }
    }
    .tabs-add-care {
        @media (max-width: 1200px) {
            > ul.nav > li.nav-item {
                margin-right: 15px;
                a.nav-link {
                    font-size: 14px;
                }
            }
        }
        @media (max-width: 1023px) {
            ul.nav li.nav-item {
                margin-right: 0;
                a.nav-link {
                    display: none;
                    &.active {
                        display: block;
                    }
                }
            }
        }
    }
    .section-title-secondary {
        margin: 20px 0;
        @media (max-width: 767px) {
            margin-top: 0;
        }
        .prev-next-buttons {
            display: flex;
            .btn-theme {
                font-size: 16px;
                @media (max-width: 767px) {
                    font-size: 12px;
                    text-transform: capitalize;
                    padding: 5px;
                    min-width: 150px !important;
                }
            }
            .btn-prev {
                min-width: 50px;
                margin-right: 10px;
            }
            .btn-next img {
                margin-left: 10px;
                @media (max-width: 767px) {
                    margin-left: 5px;
                    width: 7px;
                }
            }
        }
    }
    .form-in-new-care {
        @include custom-scroll;
        height: calc(100vh - 30px - 65px - 65px - 65px - 50px);
        overflow-y: auto;
        padding-right: 10%;
        padding-left: 1px;
        @media (max-width: 1024px) {
            padding-right: 0;
        }
        @media (max-width: 767px) {
            height: auto;
        }
        .form-group {
            margin-bottom: 30px;
        }
        .license-file-uploader {
            width: 50%;
            margin-left: 20px;
            position: relative;
            &.form-group {
                @media (max-width: 900px) {
                    margin-bottom: 0 !important;
                }
            }
            .form-group {
                width: 100%;
                margin-bottom: 12px;
            }
            .custom-file-upload span {
                @include font(500, 14px, 16px);
                color: $main-link-color;
            }
            .label-add-more {
                @include font(500, 14px, 14px);
                color: $main-link-color;
                position: absolute;
                right: 0;
                top: 70px;
            }
            
            .file-indi {
                @include flex-item(flex-start, center);
                width: 100%;
                margin-bottom: 15px;
                > label {
                    min-width: 225px;
                    margin-bottom: 0;
                }
                .custom-file-upload {
                    display: flex;
                    align-items: center;
                    @media (max-width: 900px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    label {
                        @include flex-item(center, center);
                        border: 1px solid rgba($black, 0.5);
                        border-radius: 4px;
                        background: $white;
                        padding: 5px 15px;
                        height: 36px;
                        text-decoration: none;
                        margin-bottom: 0;
                        float: left;
                        @media (max-width: 900px) {
                            padding: 5px 10px;
                            height: 30px;
                        }
                    }
                    .selected-files {
                        float: left;
                        margin-left: 20px;
                        @media (max-width: 900px) {
                            margin: 5px 0 0;
                        }
                    }
                }

            }

            .prev-file {
                @include flex-item(flex-start, center);
                padding-left: 225px;
                margin-bottom: 15px;
                a {
                    @include font(500, 14px, 16px);
                    text-decoration: underline !important;
                    word-break: break-word;
                }
                span {
                    cursor: pointer;
                    padding-left: 5px;
                    img {
                        width: 24px;
                    }
                }
            }
        }
        hr {
            margin: 0 0 25px;
        }
        &.form-in-new-care-with-checks {
            padding-right: 0;
            hr.seperator {
                margin: 20px 0 50px;
            }
            .section-care {
                .checks-holder {
                    display: flex;
                    flex-wrap: wrap;
                    .form-group-radio-checkbox {
                        width: 25%;
                        margin-bottom: 30px;
                        align-items: flex-start;
                        padding-right: 15px;
                        label {
                            @include font(400, 16px, 19px);
                        }
                        @media (max-width: 1023px) {
                            width: 50%;
                            padding-right: 25px;
                        }
                        @media (max-width: 550px) {
                            width: 100%;
                            padding-right: 0;
                        }
                    }
                }
                &.section-care-services {
                    h3 {
                        margin-bottom: 50px !important;
                    }
                }
                .sub-section {
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &.sub-section-many {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    &.sub-section-wellness {
                        margin-bottom: 30px;
                        @media (max-width: 767px) {
                            margin-bottom: 0;
                        }
                    }
                    .sub-section-item {
                        width: 25%;
                        margin-bottom: 20px;
                        .checks-holder {
                            flex-direction: column;
                            .form-group-radio-checkbox {
                                width: 100%;
                            }
                        }
                        &:nth-of-type(3) {
                            width: 50%;
                        }
                        @media (max-width: 1023px) {
                            width: 50%;
                        }
                        @media (max-width: 550px) {
                            width: 100%;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                    &.sub-section-facilities {
                        .sub-section-item:nth-of-type(3) {
                            width: 25%;
                            @media (max-width: 1023px) {
                                width: 50%;
                            }
                            @media (max-width: 550px) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        &.form-in-new-care-details {
            .section-top {
                padding-bottom: 10px;
                border-bottom: 1px solid rgba($black, 0.1);
                margin-bottom: 35px;
                .section-video-image-upload {
                    padding-bottom: 10px;
                    border-bottom: 1px solid rgba($black, 0.1);
                    margin-bottom: 25px;
                }
                .img-list {
                    @include font(500, 14px, 16px);
                    padding-bottom: 5px;
                    display: block;
                }
            }
            .section-bottom {
                .four-inputs-holder {
                    @media (max-width: 1023px) {
                        flex-wrap: wrap;
                        .form-group {
                            width: calc(50% - 10px);
                            &:nth-child(even) {
                                margin-left: 20px;
                            }
                            &:not(:last-child){
                                margin-right: 0;
                            }
                        }
                    }
                    @media (max-width: 420px) { 
                        .form-group {
                            width: 100%;
                            &:nth-child(even) {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.section-video-image-upload, .availability {
    p.note {
        @include flex-item(flex-start, center);
        @include font(400, 12px, 14px);
        color: rgba($black, 0.7);
        margin: 10px 0 0;
        img {
            margin-right: 6px;
            opacity: 0.6;
        }
    }
}

label.availability {
    display: flex;
    p.note {
        margin: 0;
    }
}

.section-video-image-upload-inner {
    @include flex-item(space-between, flex-start);
    @media (max-width: 767px) { 
        flex-direction: column;
    }
    > div {
        .custom-file-upload {
            label {
                @include flex-d(center, center, column);
                width: 100%;
                min-height: 120px;
                border-radius: 4px;
                border: 1px dashed rgba($black, 0.1);
                text-decoration: none;
                img {
                    margin-bottom: 9px;
                }
                span {
                    @include font(400, 16px, 19px);
                    color: $main-btn-color;
                    text-decoration: underline;
                }
            }
        }
    }
    .video-upload {
        width: 35%;
        .prev-video {
            @include flex-item(flex-start, center);
            // padding-left: 95px;
            margin-bottom: 15px;
            @media (max-width: 1024px) {
                flex-direction: column;
                align-items: flex-start;
            }
            div {
                display: flex;
                align-items: center;
                a {
                    text-decoration: underline !important;
                    word-break: break-word;
                    color: $main-btn-color;
                }
                span {
                    cursor: pointer;
                    padding-left: 5px;
                    img {
                        width: 16px;
                    }
                }
            }
            strong {
                @include font(500, 14px, 16px);
                padding-right: 2px;
            }
        }
        @media (max-width: 1023px) {
            width: calc(50% - 5px);
        }
        @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 20px
        }
    }
    .image-upload {
        width: calc(65% - 5px);
        @media (max-width: 1023px) {
            width: calc(50% - 5px);
        }
        @media (max-width: 767px) {
            width: 100%;
        }
        .custom-file-upload label {
            span {
                color: $black;
                text-decoration: none;
                span {
                    color: $main-btn-color;
                    text-decoration: underline;
                }
            }
        }
    }
}

.image-input-holder > .section-video-image-upload-inner {
    .image-upload {
        width: calc(65% - 5px);
        @media (max-width: 1023px) {
            width: 100%;
        }
    }
}
