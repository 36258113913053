@import url('https://fonts.googleapis.com/css?family=Maven+Pro:400,500,700&display=swap');

$default-font: 'Lexend Deca', sans-serif;

// global styles
@import './assets/styles/_colors.scss';
@import './assets/styles/_mixin.scss';
@import './assets/styles/_common.scss';
@import './assets/styles/_modal.scss';
@import './assets/styles/_header.scss';
@import './assets/pages/_pages.scss';
@import './assets/styles/_time-picker.scss';

// Shared
@import './components/shared/formInput/_form-input.scss';
@import './components/shared/formCheckOrRadio/_form-check-radio.scss';
@import './components/shared/pagination/_pagination.scss';
@import './components/shared/formSelect/_form-select.scss';
@import './components/shared/fileUpload/_file-upload.scss';
@import './components/shared/radioButton/_radio-button.scss';

// Commons
@import './components/commons/footer/_footer.scss';

// Other Components
@import './components/resetPassword/_reset-password.scss';

// Provider
@import './components/provider/_provider.scss';
@import './components/provider/seniorLiving/_senior-living.scss';
@import './components/provider/seniorLiving/addNewCare/_add-new-care.scss';
@import './components/provider/seniorLiving/addNewCare/tabs/roomDetails/_room-details.scss';

// View care
@import './components/viewCare/_view-care.scss';
@import './components/viewCare/facilitiesList/_facilities.scss';
@import './components/viewCare/viewRoom/_view-room-modal.scss';

// New to MiHygge
@import './components/newToMihygge/askForDemo/_ask-for-demo.scss';

//celebrateUs
@import './components/celebrateUs/celebrateUs.scss';

// Other scss 
@import './components/accountDetails/account-details';
@import './components/accountDetails/paymentInformation/payment-card';
@import './components/accountDetailsCustomer/bookings/bookings';
@import './components/accountDetailsCustomer/whishList/whishlist';
@import './components/bankDetails/bank-details';
@import './components/careers/style';
@import './components/commons/side_nav';
@import './components/commons/values/values';
@import './components/customer/banner/banner';
@import './components/customer/checkout/checkout';
@import './components/customer/common/location-suggestion';
@import './components/customer/makeBooking/make-booking';
@import './components/customer/makeBooking/filters/filters';
@import './components/customer_support/style';
@import './components/home/banner/banner';
@import './components/home/empowerment/empowerment';
@import './components/home/providerbanner/banner';
@import './components/mihggyBlogs/style';
@import './components/podcast/style';
@import './components/pricing/style';
@import './components/provider/viewBooking/view-booking';
@import './components/shared/datePicker/date-picker';
@import './components/shared/dateRangePicker/date-range-picker';
@import './components/shared/imageGallery/image-gallery';
@import './components/shared/userLog/user-log';
@import './components/viewCare/viewRoom/imageGallery/image-gallery';
@import './constants/customer/style';



.contact-information-header img {
    display: inline;
    height: 15px;
    width: 20px;
}

.wrapper {
    display: block;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    /* top layer */
    z-index: 9999;
}

.overlay {
    display: none;
    position: fixed;
    /* full screen */
    width: 100vw;
    height: 100vh;
    /* transparent black */
    background: rgba(0, 0, 0, 0.7);
    /* middle layer, i.e. appears below the sidebar */
    z-index: 998;
    opacity: 0;
    /* animate the transition */
    transition: all 0.5s ease-in-out;
}

/* display .overlay when it has the .active class */
.overlay.active {
    display: block;
    opacity: 1;
}

#dismiss {
    width: 35px;
    height: 35px;
    position: absolute;
    /* top right corner of the sidebar */
    top: 10px;
    right: 10px;
}

.testimonial {
    color: #404040;
    padding: 15px;
    text-align: center;
    width: 100%;
    display: inline-block;
}

@media screen and (min-width: 768px) {
    .slick-slide:nth-of-type(2n+2) .testimonial {
        border-left: 1px solid #d8d8d8
    }
}

.testimonial__image {
    border-radius: 50%;
    height: 90px;
    margin-bottom: 30px;
    overflow: hidden;
    width: 90px;
    margin-left: auto;
    margin-right: auto
}

@media screen and (min-width: 768px) {
    .testimonial__image {
        height: 128px;
        margin-bottom: 42px;
        width: 128px
    }
}

.testimonial__quote {
    font-family: "Times New Roman", Serif;
    font-size: 18px;
    font-style: italic;
    font-weight: normal;
    line-height: 1.5;
    margin-bottom: 30px
}

.testimonial__quote::before {
    content: "\201C"
}

.testimonial__quote::after {
    content: "\201D"
}

@media screen and (min-width: 768px) {
    .testimonial__quote {
        font-size: 27px;
        letter-spacing: -0.3px;
        margin-bottom: 39px;
        padding-left: 30px;
        padding-right: 30px
    }
}

.testimonial__credit {
    font-family: "Proxima Nova", Sans-Serif;
    font-size: 18px;
    line-height: 1.33
}

.testimonial__author {
    font-weight: 600
}

.testimonial__location {
    font-weight: 300
}

.text-headline-2 {
    font-size: 48px !important;
    line-height: 1.1;
    text-align: center;
    margin-top: 20px;
}

.testimonimale-parent {
    margin-top: 75px !important;
}

.banner-wrapper-home-provider .title-main {
    font-size: 52px !important;
    line-height: 60px !important;
    margin-bottom: 45px !important;
}

.drop_down_link {
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.7);
}

.phone-class img {
    display: inline;
}

.navbar-toggler-icon {

    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.chemargin {
    margin: 5px 0 0 5px;
}

.heading_color {
    color: #63120d
}

.new5 {
    border: 2px solid #63120d;
    margin-bottom: 20px;
}

.carrier-resume {
    color: #63120d;
    font-weight: bolder;
    font-style: italic;
}

.textarea-field {
    height: 100px !important;
}

.row-width {
    width: 100%;
}

.carrier-content {
    padding-top: 20px;
}

.plan-list {
    list-style-type: none;
}

.plan-list li {
    line-height: 25px;
}

.plan-list li.first {
    line-height: 50px;
}

.plan-list li strong {
    font-size: 24px;
    border-bottom: 2px solid #63120d;
}

.plan-button {
    margin-top: 5px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .img-dimension {
        width: 15px !important;
    }

    .font-size-15 {
        font-size: 15px !important;
    }

    .btn-size-15 {
        font-size: 15px !important;
        min-height: 30px;
        min-width: 150px;
    }
}

@media (max-width: 768px) {
    .flex-end {
        align-items: flex-end !important;
    }

    .header-navbar {
        width: 100%;
    }

    .margin-right {
        margin-right: 0 !important;
        margin-bottom: 5px;
    }

    .navbar-logo {
        float: left;
    }

    // .header-toggler {
    //     float: right;
    //     margin-top: 30px;
    // }
    .header-containt {
        float: right;
        width: 100%;
    }
}

// @media (max-width: 576px) {
//     .header-toggler {
//         margin-top: -55px;
//     }
// }

.btn-block {
    float: right;
    width: 100%;
    padding: 40px 0;

    .links {
        background-color: #63120d;
        border-color: #63120d;
        color: #fff;
        border-radius: 8px;
        float: right;

        &:hover {
            color: #63120d;
        }
    }
}

.heading-block {
    float: left;
    width: 100%;
    margin-bottom: 40px;

    h1 {
        margin-bottom: 0 !important;
    }
}

.rhap_progress-section {
    position: relative;
    padding-bottom: 22px;

    #rhap_current-time {
        position: absolute;
        left: 0;
        bottom: 0;
        color: #fff;
    }

    .rhap_total-time {
        position: absolute;
        right: 0;
        bottom: 0;
        color: #fff;
    }
}

.rhap_main {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
}

.rhap_progress-container {
    background-color: rgba(255, 255, 255, 0.45);
    height: 4px;
    border-radius: 10px;
    position: relative;

    .rhap_progress-filled {
        background: #63120d !important;
        height: 4px;
        border-radius: 10px;
    }

    .rhap_progress-indicator {
        background: #ffffff !important;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: block;
        position: absolute;
        top: -3px;
        margin-left: -2px;
        border: 3px solid #63120d;
        cursor: pointer;
    }
}

.header-wrapper .navbar .nav-right li.nav-item .nav-link,
.btn-signin {
    color: #63120d !important;
}

#btn-demo {
    background: #63120d !important;
    border: 1px solid #63120d !important;
}

.btn-block .links {
    background: #63120d !important;
}

.language-block .MuiButton-label {
    color: #63120d !important;
}

#chatbot-chat {
    z-index: 998 !important;
}

.language-block .MuiSvgIcon-fontSizeSmall {
    fill: #63120d !important;

    .country-width {
        width: 25%;

        a:hover {
            color: #dd4a2c !important;
        }

        @media (min-width: 768px) and (max-width: 1199.98px) {
            width: 50%;
        }

        @media (max-width: 767px) {
            width: 100%;
        }
    }
}

.loaderBox {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.575);
    z-index: 1000;
}

.loader5 {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 45px solid transparent;
    border-right: 45px solid transparent;
    border-bottom: 45px solid #63120d;
    border-top: 45px solid #de4928;
    -webkit-animation: loader5 1.2s ease-in-out infinite alternate;
    animation: loader5 1.2s ease-in-out infinite alternate;
}

.videoTemplate {
    padding: 60px 0;
}

.videoCard {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 15px;
    margin: 15px 0;

    &>div {
        width: 100% !important;
        height: 210px !important;
    }

    video {
        width: 100%;
    }

    &:hover {
        box-shadow: 0px 0px 22px -4px rgba(0, 0, 0, 0.28);
        border-color: #fff;
    }
}



@keyframes loader5 {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(720deg);
    }
}

@-webkit-keyframes loader5 {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(720deg);
    }
}