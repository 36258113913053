.customised-search-container {
    position: relative;
    .form-group {
        margin-bottom: 0 !important;
    }
    .customised-search-results {
        background: #fff;
        border-radius: 6px;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 300px;
        overflow-y: auto;
        border: 1px solid #a8a1a1;
        &:empty {
            border: none;
        }
        @media (max-width: 1450px) {
            max-height: 200px;
        }
        @media (max-width: 1200px) {
            max-height: 100px;
        }
        @media (max-width: 576px) {
            max-height: 75px;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            h3 {
                font-weight: 700 !important;
                font-size: 14px !important;
                margin: 0 !important;
                padding: 5px 10px;
                background: rgba(#a8a1a1, 0.2);
                text-transform: uppercase;
            }
            li {
                padding: 10px;
                text-transform: capitalize;
                border-bottom: 1px solid  rgba(#a8a1a1, 0.2);
                &.no-results {
                    color: #000;
                    font-weight: 500 !important;
                    text-transform: none;
                }
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
}

.text-align-center {
    text-align: -webkit-center;
}