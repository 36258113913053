.section-room-deatils-in-tabs {
    position: relative;
    .section-room-details-outer {
        position: relative;
        &:before {
            position: absolute;
            content: "";
            height: 100%;
            width: 200px;
            background-color: rgba($black, 0.03);
            left: 0;
            top: 0;
            border-radius: 10px 0 0 10px;
            @media (max-width: 1023px) {
                width: 150px;
            }
            @media (max-width: 767px) {
                display: none;
            }
        }
    }
    .btns-save-in-room {
        @include flex-item(flex-end, center);
        position: absolute;
        z-index: 99;
        width: calc(100% - 202px);
        height: 64px;
        background: $white;
        right: 1px;
        top: 1px;
        padding-right: 35px;
        border-radius: 8px;
        @media (max-width: 1023px) {
            width: calc(100% - 152px);
        }
        button {
            min-width: 80px;
            min-height: 30px;
            padding: 2px 10px;
            text-transform: capitalize;
            font-size: 16px;
            &:first-child {
                margin-right: 20px;
            }
        }
        @media (max-width: 767px) {
            position: static;
            justify-content: center;
            width: 100%;
            padding: 0 0 20px;
            height: auto;
        }
    }
    .section-header {
        @include flex-item(space-between, center);
        margin-bottom: 20px;
        @media (max-width: 767px) {
            border-bottom: 1px solid rgba($black, 0.1);
            padding-bottom: 15px;
        }
        h3 {
            margin-bottom: 0 !important;
        }
        > div {
            display: flex;
            &.form-group {
                margin-bottom: 0 !important;
            }
            .btn-no-box {
                color: $main-btn-color;
                margin-right: 15px;
            }
            @media (max-width: 900px) {
                .btn-theme {
                    font-size: 14px;
                    min-width: 140px;
                }
            }
            @media (max-width: 767px) {
                .btn-theme {
                    font-size: 12px;
                    line-height: 14px;
                    min-width: 0;
                    min-height: 32px;
                    &.btn-no-box {
                        margin-right: 8px;
                    }
                }
            }
        }
        .rooms-selector {
            min-width: 100px;
            margin-bottom: 0 !important;
            .select-dropdown__menu {
                z-index: 9;
            }
        }
    }
    .section-room-details {
        height: calc(100vh - 30px - 65px - 100px - 65px - 75px);
        overflow-y: auto;
        display: flex;
        border: 1px solid rgba($black, 0.1);
        border-radius: 10px;
        position: relative;
        z-index: 9;
        @media (max-width: 767px) {
            height: auto;
            border: none;
            z-index: 1;
        }
        .nav-rooms-container {
            height: 100%;
            overflow-y: auto;
            width: 200px;
            ul.nav {
                li.nav-item {
                    width: 100%;
                    a.nav-link {
                        @include flex-item(flex-start, center);
                        @include font(400, 16px, 19px);
                        color: $black;
                        min-height: 60px;
                        padding: 10px 10px 10px 30px;
                        &:hover, &.active {
                            color: $main-btn-color;
                            background-color: $white;
                        }
                    }
                    &:first-child:not(:last-child) {
                        a.nav-link {
                            border-radius: 10px 0 0 0;
                        }
                    }
                }
            }
            @media (max-width: 1023px) {
                width: 150px;
                ul.nav li.nav-item a.nav-link {
                    padding-left: 10px;
                }
            }
            @media (max-width: 767px) {
                display: none;
            }
        }
        .tab-content {
            width: calc(100% - 200px);
            padding: 65px 30px 30px;
            display: flex;
            position: relative;
            .tab-content-rooms-disabled {
                pointer-events: none;
                opacity: 0.5;
            }
            .title-room {
                margin-bottom: 50px !important;
                @media (max-width: 767px) {
                    display: none;
                }
                .room-no {
                    margin-bottom: 5px !important ;
                }
            }
            @media (max-width: 1023px) {
                padding-right: 10px;
                width: calc(100% - 150px);
            }
            @media (max-width: 767px) {
                width: 100%;
                padding: 0;
            }
        }
    }
}

.form-in-new-care.form-in-new-care-room-details {
    height: 100%;
    overflow-y: unset;
    padding-right: 0;
    .section-bed-details {
        @include flex-d(flex-start, flex-start, column);
        margin-bottom: 20px;
        .multiple-inputs-holder {
            width: 100%;
            > span.error-message{
                margin-bottom: 20px;
                display: block;
            }
        }
        button {
            min-width: 150px; 
            &.btn-remove-bed {
                background-color: #dc3545;
                text-transform: capitalize;
            }
            @media (max-width: 767px) {
                font-size: 16px;
                min-height: 36px;
            }
        }
        
        .beds-input-holder {
            border: 1px solid rgba($black, 0.2);
            border-radius: 10px;
            margin-bottom: 15px;
            padding: 10px;
            @media (max-width: 1023px) {
                .form-group:last-child {
                    margin-bottom: 0 !important;
                }
                button {
                    margin: 0;
                }
            }

            .three-inputs-holder {
                button {
                    margin-top: 19px;
                }
                @media (max-width: 1023px) {
                    margin-bottom: 15px;
                    .form-group:last-child {
                        margin-bottom: 0 !important;
                    }
                    button {
                        margin: 0;
                    }
                }
            }            
        }
    }
    .calendar-input-holder {
        display: flex;
        .input-price {
            width: calc((100% / 3) - 10px);
            margin-right: 20px; 
        }
        .calendar-room {
            width: calc(((100% / 3)*2) - 10px);
        }
        @media (max-width: 1023px) {
            flex-direction: column;
            .input-price, .calendar-room {
                @media (max-width: 1023px) {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
    }
    .section-video-image-upload {
        padding: 30px 0;
        border-top: 1px solid rgba($black, 0.1);
        border-bottom: 1px solid rgba($black, 0.1);
        @media (max-width: 1023px) {
            .section-video-image-upload-inner {
                flex-direction: column;
            }
            .video-upload, .image-upload {
                width: 100%;
            }
            .video-upload {
                margin-bottom: 25px;
            }
        }
    }
    .checks-holder {
        margin-bottom: 20px;
        width: 100%;
        .form-group-radio-checkbox {
            width: calc(100% / 3) !important;
            @media (max-width: 1023px) {
                width: 100% !important;
            }
        }
        @media (max-width: 767px) {
            margin-bottom: 0;
        }
    }
    .section-checks-one {
        padding: 50px 0 0;
        border-bottom: 1px solid rgba($black, 0.1);
    }
    .section-checks-two {
        h3 {
            margin: 50px 0 !important;
        }
    }

    .three-inputs-holder {
        @media (max-width: 1023px) {
            flex-wrap: wrap;
            .form-group {
                width: 100%;
                margin: 0;
            }
        }
    }
}

sup{
    color: #ff0000 !important;
}