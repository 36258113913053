@import '../../../assets/styles/_colors.scss';
@import '../../../assets/styles/_mixin.scss';

.banner-wrapper-home {
    // background: url("../../../assets/images/theme.png") no-repeat center;
    background-size: cover;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        // background: linear-gradient(0deg, #000000 -5.32%, rgba(0, 0, 0, 0) 113.08%);
    }

    .container-items {
        @include flex-d(flex-end, center, column);
        min-height: 550px;
        position: relative;
        z-index: 9;
        padding-bottom: 160px;

        @media (max-width: 768px) {
            min-height: 450px;
            padding-bottom: 140px;
        }
    }

    .title-main {
        @include font(700, 60px !important, 70px !important);
        color: $white;
        text-shadow: 3px 3px 6px #63120d;
        margin-bottom: 20px !important;
        text-transform: none;
    }

    .section-search-and-buttons {
        width: 720px;

        .search-holder {
            position: relative;
            margin-bottom: 30px;

            @media (max-width: 768px) {
                margin-bottom: 10px;
            }

            .form-control {
                min-height: 56px;
                border-radius: 6px;

                &::placeholder {
                    font-weight: 500;
                }
            }

            .icon-search {
                position: absolute;
                width: 60px;
                height: 20px;
                right: 18px;
                top: 20px;

                @media (max-width: 500px) {
                    width: 50px;
                    right: 0px;
                }
            }
        }
    }

    .buttons-container {
        @include flex-item(space-between, center);

        .btn-theme {
            min-width: 350px;
            min-height: 56px;
            text-transform: capitalize;
            border-width: 2px;
        }
    }
}

.banner-description {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    @media (max-width: 600px) {
        top: 60%;
        width: 90%;
        // width: fit-content;
    }
}

.locationSearchBox {
    input {
        width: 100%;
        padding: 10px 15px;
        min-height: 56px;
        border: 1px solid #666;
        border-radius: 6px;
    }

    &__list {
        padding: 2px;
        border: 1px solid #aaa;
        background: #f5f5f5;
        border-radius: 5px;
        margin-top: 1px;
        position: absolute;
        width: 100%;
        list-style-type: none;

        li {
            padding: 10px 15px;
            cursor: pointer;

            &:not(:last-child) {
                border-bottom: 1px solid #ccc;
            }

            &:hover {
                background-color: #ddd;
            }
        }
    }
}

/* Media query for banner */
// Large devices (desktops, 1200px and up)
@media (max-width: 1200px) {
    .banner-wrapper-home {
        .title-main {
            font-size: 42px !important;
            line-height: 60px !important;
            text-align: center;
        }
    }
}

@media (max-width: 768px) {
    .banner-wrapper-home {
        .title-main {
            margin-bottom: 10px !important;
        }
    }
}

@media (width: 768px) {
    .banner-wrapper-home {
        .title-main {
            font-size: 36px !important;
            line-height: 47px !important;
        }
    }
}

@media (max-width: 767px) {
    .banner-wrapper-home {
        .title-main {
            font-size: 30px !important;
            line-height: 35px !important;
            // margin-bottom: 24px !important;
            text-align: center;
        }

        .section-search-and-buttons {
            width: 100%;

            .search-holder {
                // margin-bottom: 32px;

                .form-control {
                    font-size: 14px;
                    line-height: 16px;
                    padding-right: 38px;
                }
            }
        }

        .buttons-container {
            flex-direction: column;

            .btn-theme {
                min-width: 100%;
                margin-bottom: 16px;
                font-size: 16px;
                line-height: 19px;
            }
        }

        .container-items {
            padding-bottom: 80px;
        }
    }
}

.desktop-view {
    display: block !important;
}

.mobile-view {
    display: none !important;
}

@media (max-width: 600px) {
    .mobile-view {
        display: block !important;
    }

    .desktop-view {
        display: none !important;
    }
}