@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_mixin.scss';

.container-values {
    padding-bottom: 100px;

    @media (max-width: 767px) {
        padding-bottom: 50px !important;
    }

    .tab-pane {
        max-width: 90%;
        margin: 0 auto;

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }

    .tab-content {
        h3.title {
            @include font(700, 24px, 28px);
            color: $black;
            margin-bottom: 15px !important;
        }

        .description {
            margin-bottom: 0;

            p,
            ol li {
                @include font(400, 18px, 28px);
                color: $black;

                @media (max-width: 767px) {
                    font-size: 16px;
                    line-height: 21px;
                }
            }

            div {
                margin-bottom: 25px;

                h4 {
                    margin-bottom: 10px !important;
                }

                ol {
                    padding: 0 0 0 20px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.section-values.nav-tabs {
    >li {
        padding: 24px 50px;
        border: 1px solid #CCCCCC;
        min-width: 260px;
    }

    @include flex-item(space-between, flex-start);
    padding: 65px 0 0;
    border-bottom: 0;

    @media (max-width: 767px) {
        padding: 30px 0;
    }

    .nav-item {
        margin-bottom: 0;

        .nav-link {
            border: none;
            padding: 0 0 5px;
            cursor: pointer;
            border-bottom: 1px solid $white;

            &.active {
                border-bottom-color: rgba($grey, 0.5);
            }
        }

        @media (max-width: 767px) {
            width: 25%;
            padding: 0 15px;
        }

        @media (max-width: 600px) {
            width: 50%;
            margin-bottom: 30px;
        }

        @media (max-width: 370px) {
            width: 100%;
        }
    }

    .card-values {
        @include flex-d(center, center, column);

        .img-icon {
            margin-bottom: 20px;
            height: 60px;
        }

        h4 {
            margin-bottom: 10px !important;
            text-align: center;
        }

        a,
        button {
            @include font(500, 16px, 19px);
            text-transform: none;

            img {
                margin: 2px 0 0 4px;
            }
        }

        .arrow {
            display: inline;
        }

        .learnMore {
            color: #63120d;
        }

    }
}

.contentTemplate {
    padding: 65px 20px;

    .custom-container {
        padding: 0 !important;
    }

    h1 {
        font-size: 42px !important;
        line-height: 40px !important;
        color: #000;
        margin: 0 0 45px !important;
        font-weight: 700 !important;
        text-align: center;
    }

    h2 {
        font-size: 32px !important;
        line-height: 40px !important;
        color: #63120d;
        margin: 0 0 45px !important;
        font-weight: 700 !important;
    }

    h3 {
        font-size: 30px !important;
        line-height: 40px !important;
        color: #000;
        margin: 0 0 45px !important;
        font-weight: 700 !important;
    }

    h4 {
        font-size: 20px !important;
        line-height: 36px !important;
        margin: 30px 0 20px !important;
        font-weight: 700 !important;
        color: #63120d;
    }

    h6 {
        font-size: 16px !important;
        line-height: 28px !important;
        margin: 30px 0 20px !important;
        font-weight: 700 !important;
        color: #63120d;
    }

    h5 {
        font-size: 18px !important;
        line-height: 28px !important;
        margin: 0 0 5px !important;
        font-weight: 700 !important;
        color: #000000;
    }

    p {
        font-size: 14px;
        line-height: 22px;
        color: #2e475d;
        opacity: 80%;
    }

    ul {
        padding: 0;
        margin: 0 0 40px;
        list-style-type: none !important;

        li {
            font-size: 14px;
            line-height: 22px;
            color: #2e475d;
            opacity: 80%;
            margin: 0 0 10px;

            p {
                opacity: 100%;
            }

            b {
                font-weight: 700 !important;
                font-size: 14px;
                line-height: 22px;
                color: #2e475d;
            }

            li {
                opacity: 100%;
            }
        }

        ul {
            padding-left: 30px;
        }
    }

    p {
        ul {
            list-style-type: disc !important;
            padding-left: 18px;
        }
    }

    .nav.nav-tabs {
        margin: 60px 0 20px !important;
        border-bottom: 1px solid #63120d;
    }

    .nav-item {
        margin-bottom: 0 !important;
        cursor: pointer;

        .nav-link {
            color: #4c4c4c;
            font-weight: 600 !important;

            &.active {
                color: #63120d;
                border-color: #63120d #63120d #fff;
            }
        }
    }
}

.quaBlock {
    margin-bottom: 20px;

    h6 {
        margin-bottom: 6px !important;
        font-size: 18px !important;
    }
}

@media only screen and (max-width: 768px) {
    .contentTemplate {
        h1 {
            font-size: 34px !important;
            line-height: 40px !important;
        }

        h2 {
            font-size: 28px !important;
            line-height: 36px !important;
            margin: 0 0 30px !important;
        }

        h3 {
            font-size: 26px !important;
            line-height: 36px !important;
            margin: 0 0 30px !important;
        }

        .nav.nav-tabs {
            margin: 40px 0 20px !important;
        }

        .nav-item .nav-link {
            padding: 8px 10px;
            font-size: 14px;
        }
    }
}