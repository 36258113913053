.header-wrapper {
  position: sticky;
  z-index: 999;
  top: 0;
  left: 0;
  background: $white;
  border-bottom: 1px solid rgba($black, 0.1);
  padding: 16px 0;
  .custom-container > .navbar {
    justify-content: space-between;
  }
  .navbar {
    @include flex-item(flex-end, center);
    padding: 0;
    .nav-right {
      align-items: right;
      @media screen and (device-aspect-ratio: 40/71) {
        .w-50 {          
        width: 52%;
        }
      }
      @media (max-width: 767px) {
        align-items: end;
      } 
      li.nav-item {
        ul {
          list-style: none;
        }
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
        button,
        a,
        .nav-link {
          @include font(400, 18px, 21px);
          color: $black;
          text-transform: none;
          padding: 0;
          &.btn-signin {
            font-weight: 700 !important;
            color: $main-link-color;
          }
          &:hover,
          &:focus {
            color: $black;
            &.btn-signin {
              color: $main-link-color;
            }
          }
        }
      }
    }
    .nav-main.nav {
      margin-right: 40px;
      flex-direction: row;
      @media screen and (device-aspect-ratio: 40/71) {
        margin-right: 0px;
      }
      .nav {
        flex-direction: row;
      }
      .nav-item {
        &:not(:last-child) {
          margin-right: 55px;
          @media (max-width: 767px) {
            margin-right: 0px;
          }
        }
        .nav-link {
          @include font(500, 14px, 16px);
          color: rgba($black, 0.6);
          padding: 0;
          &.active, &:hover, &:focus {
            color: $black;
          }
        }
        &.dropdown {
          .nav-link {
            position: relative;
            padding-right: 12px;
            &:after {
              position: absolute;
              content: "";
              width: 0;
              height: 0;
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              border-top: 4px solid rgba($black, 0.6);
              right: 0;
              top: 50%;
            }
          }
          .dropdown-menu {
            padding: 30px 25px;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            min-width: 210px;
            border: none;
            top: 15px !important;
            left: -60px !important;
            background: $white;
            .dropdown-item, a {
              @include font(500, 14px, 16px);
              color: rgba($black, 0.6);
              display: block;
              padding: 2px;
              &:not(:last-child) {
                margin-bottom: 20px;
              }
              &:hover, &:focus, &.active {
                color: $black;
              }
            }
            &::before {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              top: 0;
              right: 10px;
              border: 7px solid $black;
              border-color: transparent transparent $white $white;
              transform-origin: 0 0;
              transform: rotate(135deg);
              box-shadow: -1px 1px 0px rgba(0, 0, 0, 0.01);
            }
          }
          &.show {
            .nav-link {
              &:after {
                border-top: none;
                border-bottom: 5px solid rgba($black, 0.6);
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
  .nav {
    flex-direction: column;
  }
}
}

a.header-logo {
  @include flex-item(center, center);
  text-decoration: none !important;
  justify-content: flex-start;
  text-decoration: none !important;
  max-width: 250px;
  img {
    width: 75px;
    @media (max-width: 576px) {
      width: 60px;
    }
  }
  span {
    @include font(800, 36px, 36px);
    display: block;
    @media (max-width: 576px) {
      @include font(800, 33px, 36px);
    }
  }
  .mycrozyliving{
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 600 !important;
    font-size: 14px;
  }
}

.main-logoText {
  width: 100%;
  padding-left: 8px;
}

.leftside{margin-left:-90px;}
@media (max-width: 767px) {
.leftside{margin-left:0px;}
.wordWrap { white-space: normal !important; }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .wordWrap { white-space: normal !important; }
}

.navbar-toggler {
background: #63120d !important;
}
.flex-container-header{
  display: flex;
  flex-direction: row-reverse;
}
.flex-container-header-ul{
    display: flex;
    align-items: flex-end;
}
.menu-padding{
  padding: 5px;
}
.navbar-expand-md .navbar-nav .dropdown-menu {
    padding: 10px;
}
