@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_mixin.scss';

.container-values {
  padding-bottom: 100px;
  @media (max-width: 767px) {
      padding-bottom: 50px !important;
  }
  ul li {
    width: 32%;
    margin: 5px;
    @media (max-width: 769px) {
      width: 47%;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
}