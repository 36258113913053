// Colors
$white:#fff;
$main-link-color:#63120d;
$main-btn-color:#63120d;
$radio-btn-color: #fcedea;
$field-error: rgba(255, 12, 12, 0.7);
$btn-hover:rgb(197, 50, 21);
$text-erorr:rgba(255, 0, 0, 0.7);
$black:#000;
$text-color-sub:rgba(0, 0, 0, 0.7);
$gray-text:rgba(0, 0, 0, 0.5);
$grey: #a8a1a1;
$border-color: #f0b9ae;
$border-light: #dadada;
$status-pending: #e3ba2b;
$status-active: #1da356;

// Font variable 
$default-font: 'Lexend Deca', sans-serif;