@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_mixin.scss';

.container-values {
    padding-bottom: 100px;

    @media (max-width: 767px) {
        padding-bottom: 50px !important;
    }

    .tab-pane {
        max-width: 90%;
        margin: 0 auto;

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }

    .tab-content {
        h3.title {
            @include font(700, 24px, 28px);
            color: $black;
            margin-bottom: 15px !important;
        }

        .description {
            margin-bottom: 0;

            p,
            ol li {
                @include font(400, 18px, 28px);
                color: $black;

                @media (max-width: 767px) {
                    font-size: 16px;
                    line-height: 21px;
                }
            }

            div {
                margin-bottom: 25px;

                h4 {
                    margin-bottom: 10px !important;
                }

                ol {
                    padding: 0 0 0 20px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.section-values.nav-tabs {
    >li {
        padding: 24px 50px;
        border: 1px solid #CCCCCC;
        min-width: 260px;
    }

    // @include flex-item(space-between, flex-start);
    padding: 65px 0 0;
    border-bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;

    @media (max-width: 767px) {
        padding: 30px 0;
    }

    .nav-item {
        margin-bottom: 0;
        height: 100%;

        .nav-link {
            border: none;
            padding: 0 0 5px;
            cursor: pointer;
            border-bottom: 1px solid $white;

            &.active {
                border-bottom-color: rgba($grey, 0.5);
            }
        }

        @media (max-width: 767px) {
            width: 25%;
            padding: 0 15px;
        }

        @media (max-width: 600px) {
            width: 50%;
            margin-bottom: 30px;
        }

        @media (max-width: 370px) {
            width: 100%;
        }
    }

    .card-values {
        @include flex-d(center, center, column);

        .img-icon {
            margin-bottom: 20px;
            height: 60px;
        }

        h4 {
            margin-bottom: 10px !important;
            text-align: center;
        }

        a,
        button {
            @include font(500, 16px, 19px);
            text-transform: none;

            img {
                margin: 2px 0 0 4px;
            }
        }

        .arrow {
            display: inline;
        }

        .learnMore {
            color: #63120d;
        }

    }
}



// 04-08-2022
.pageTitle {
    font-size: 32px !important;
    line-height: 36px !important;
    font-weight: 900 !important;
    margin: 0 0 25px !important;
}

// PRODCAST PAGE CSS
.main-page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 25px;

    .pageTitle {
        margin: 0;
    }
}

.mainPage-search-filter {
    display: flex;
    align-items: center;
    max-width: 400px;
    width: 100%;
    position: relative;

    input {
        width: 100%;
        height: 40px;
    }

    button {
        position: absolute;
        left: 0;
        padding: 8px 15px;
        height: 40px;
        border: 1px solid #f1f1f1;
        width: 86px;
        text-align: center;
        background: #f1f1f1;
    }
}

.search-filter {
    width: 100% !important;
    margin-bottom: 40px;
    box-shadow: none !important;
    border: none;
    border-bottom: 1px solid #bbb;

    &::before {
        display: none;
    }

    &>div {
        padding: 0 0 25px !important;
    }

    .filterList-title {
        font-size: 16px;
        font-weight: 600 !important;
        border-bottom: 1px solid #bbb !important;
        padding-bottom: 6px;
        margin: 0 0 20px;
    }

    .filterList-items {
        display: flex;
        flex-direction: column;

        button {
            margin: 6px 0 0;
            text-align: left;
            justify-content: flex-start;
            padding: 3px;
            font-size: 14px;
            color: #000;
        }

    }
}

.mediaPostBlock {
    margin-bottom: 50px;

    &__title {
        font-size: 24px !important;
        line-height: 28px !important;
        font-weight: 500 !important;
        margin: 0 0 18px !important;
        color: #000 !important;
    }

    &__group {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 16px;
    }
}

.mediaPost {
    cursor: pointer !important;

    &__img {
        height: 190px !important;
        margin-bottom: 10px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__title {
        font-size: 16px;
        line-height: 1.25rem;
        color: #000;
        font-weight: 900 !important;
        transition: all 0.2s ease;
    }

    &__text {
        font-size: 13px;
        line-height: 16px;
        color: #3a3c3e;
        margin: 0 0 5px;
    }

    &:hover {
        .mediaPost__title {
            text-decoration: underline;
            color: #d24712;
        }
    }
}


// SERIES PAGE CSS 
.mediaSeries-header {
    padding: 48px 15px 10px;
    border-bottom: 1px solid #8a8c8e;

    .custom-container {
        padding-bottom: 0;
    }

    .pageTitle {
        margin-bottom: 10px !important;
    }

    &__text {
        font-size: 18px;
        line-height: 22px;
        color: #3a3c3e;
        margin: 0;
    }

    &__status {
        font-size: 12px;
        margin: 32px 0 0 !important;
        color: #000 !important;
        display: block;

        b {
            font-size: 12px;
            color: #000;
        }
    }
}

.mediaSeries {
    padding: 16px 0;
    border-bottom: 1px solid #8a8c8e;
    display: flex;
    cursor: pointer !important;

    &__img {
        height: 140px;
        width: 140px;
        margin-right: 16px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &::before {
            content: '';
            width: 0;
            height: 0;
            border-top: 16px solid transparent;
            border-bottom: 16px solid transparent;
            border-left: 26px solid #fff;
            position: absolute;
            transition: all 0.2s ease;
            z-index: 2;
            opacity: 0;
        }

        &:after {
            content: '';
            height: 100%;
            width: 100%;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            background-color: #000;
            opacity: 0;
            transition: all 0.2s ease;
            z-index: 1;
        }
    }

    &__title {
        font-size: 16px;
        line-height: 1.25rem;
        color: #000;
        font-weight: 900 !important;
        transition: all 0.2s ease;
    }

    &__text {
        font-size: 13px;
        line-height: 16px;
        color: #3a3c3e;
        margin: 0 0 5px;
    }

    &__date-time {
        font-size: 13px;
        line-height: 16px;
        color: #3a3c3e;
        margin: 10px 0 5px;
        display: flex;
        align-items: center;

        span {
            font-size: 20px;
            line-height: 16px;
            display: inline-block;
            margin: 0 6px;
        }
    }

    &:hover {
        .mediaSeries__title {
            text-decoration: underline;
            color: #d24712;
        }

        .mediaSeries__img::after {
            opacity: 80%;
        }

        .mediaSeries__img::before {
            opacity: 100%;
        }
    }
}

// PLAY PAGE CSS

.rhap_container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .rhap_header {
        margin-bottom: 50px;
        font-size: 28px;
        color: #fff;
        font-weight: 500 !important;
        width: 100%;
        padding-left: 300px;
    }
}

.seriesPlayArea {
    height: 500px;
    padding: 10px 20px;
    // background-color: #000;
    position: relative;
    background: rgb(31, 5, 3);
    background: linear-gradient(90deg, rgba(31, 5, 3, 1) 0%, rgba(0, 0, 0, 1) 100%);
    margin-bottom: 20px;

    &>div {
        max-width: 1180px !important;
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }

    .rhap_controls-section {
        display: flex;
        justify-content: space-around;
        align-items: flex-end;

        button {
            border: none;
            box-shadow: none;
        }
    }

    button {
        background-color: transparent;
        color: #fff;

        svg {
            font-size: 30px;

            path {
                color: #ccc;
            }
        }
    }

    .rhap_main-controls {
        svg {
            font-size: 50px;

        }
    }
}

.singleSeriesInfo {
    padding: 24px 0 64px;

    p {
        font-size: 16px;
        line-height: 20px;
        color: #3a3c3e !important;
        margin: 0 0 12px !important;

        span {
            margin-left: 16px;
        }

        b {
            font-size: 16px;
            line-height: 20px;
            color: #3a3c3e !important;
        }
    }

    &__btns {
        margin-top: 32px;

        button {
            font-size: 12px;
            line-height: 16px;
            padding: 0 32px;
            border: 1px solid #545658;
            background: transparent;
            color: #141414;
            text-transform: uppercase;
            border-radius: 0;
            font-weight: 600 !important;
            height: 50px;
            min-width: 250px;
            margin-bottom: 8px;

            &:not(:last-child) {
                margin-right: 8px;
            }

            &:hover {
                background: transparent;
                text-decoration: underline;
                color: #d24712;
            }
        }
    }

    &__buttonComment {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__comments {
        display: flex;
        align-items: center;
    }
}


.filter-search-box {
    float: right;
    width: 50%;
    border: 1px solid #aaa;
    border-radius: 8px;
    font-size: 16px;
    padding: 2px 15px;
    margin-left: 92px;
}

// .search-filter {
//     float: right;
//     width: 50%;
// }

.mediaPostBlock {
    float: left;
    width: 100%;
}

.videoEpisode-image {
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    max-width: 1180px;
    margin: 0 auto;
    left: 0;
    right: 0;
    height: auto !important;
    padding: 10px 20px;

    img {
        max-width: 250px;
    }
}

.langModel {
    padding: 15px;

    .MuiBox-root {
        max-height: 95vh;
        max-width: 850px;
        width: calc(100% - 30px);
        border-radius: 15px;
        border: none;
        overflow-y: scroll;
        padding: 0;
        position: relative;

        &::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #ddd;
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #bbb;
        }
    }

    .MuiList-root {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px;
        padding: 0 30px 30px;

        .MuiButtonBase-root {
            padding: 12px;
            border-radius: 10px;
            border: 1px solid #eee;

            &:hover {
                background-color: #eee;
            }

            img {
                height: 24px;
                width: 30px;
                border-radius: 5px;
                object-fit: cover;
                border: 1px solid #eee;
            }

            p {
                font-size: 14px;
                line-height: 18px;
                color: #000;
                font-weight: 500;
                margin: 0;
            }

            &.activeLang {
                border-color: #63120d;
                background-color: #eee;

                p {
                    font-size: 14px;
                    line-height: 18px;
                    color: #63120d;
                }
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 30px;
        background: #fff;
        z-index: 1;

        button {
            border: none;
            background: transparent;
            font-size: 20px;
            color: #000;
        }
    }

    &__title {
        font-size: 24px;
        color: #333;
        line-height: 30px;
        font-weight: 600 !important;
        margin: 0;
    }
}

.video-card-media>div {
    width: 100% !important;
}



// new Theme 
.a.header-logo span {
    color: #2e475d !important;
}

.header-containt .navbar.navbar-nav a {
    font-family: 'Lexend Deca', sans-serif !important;
    color: #2e475d !important;
    font-size: 16px !important;
}

.header-containt .btn-signin {
    font-family: 'Lexend Deca', sans-serif !important;
    color: #2e475d !important;
}

.language-block .MuiButton-label {
    font-family: 'Lexend Deca', sans-serif !important;
    color: #2e475d !important;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 8px;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.25em solid;
    border-right: 0.25em solid transparent;
    border-bottom: 0;
    border-left: 0.25em solid transparent;
}

.btn-block {
    padding: 0;

    a {
        font-family: 'Lexend Deca', sans-serif !important;
        background: #df664e !important;
        border-radius: 50px !important;
        padding: 8px 30px !important;
        font-size: 16px;
        font-weight: 500 !important;
        border: 2px solid #df664e;
        color: #fff;
        transition: all 0.2s ease;

        &:hover {
            background: #fff !important;
            color: #df664e;
        }
    }
}


.mainPage-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 65px 0 40px;

    .btn-block {
        width: auto !important;
    }

    &__title {
        // width: 100%;

        h1 {
            font-family: 'Lexend Deca', sans-serif;
            font-size: 36px !important;
            line-height: 40px !important;
            font-weight: 600 !important;
            color: #2e475d;
            margin: 0 0 8px !important;
        }

        p {
            font-family: 'Lexend Deca', sans-serif;
            margin: 0;
            color: #de4928;
            font-weight: 500 !important;
            font-size: 16px;
        }
    }
}


.video-card-block {
    overflow: hidden;

    .video-card-media {
        &>div {
            height: 208px !important;
            width: 100% !important;
        }
    }

    .Video-item p {
        font-size: 15px;
        line-height: 28px;
        font-family: 'Lexend Deca', sans-serif;
        margin: 0;
        color: #2e475d;
        opacity: 80%;
    }

    &:hover {
        box-shadow: 0px 0px 22px -4px rgba(0, 0, 0, 0.28);
        border-color: #fff;
    }

    .video-card-image {
        height: 208px !important;
        width: 100% !important;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

#btn-demo {
    font-family: 'Lexend Deca', sans-serif !important;
    background: #63120d;
    border-radius: 5px !important;
    padding: 8px 30px;
    font-size: 14px;
    font-weight: 500 !important;
    border: 2px solid #63120d !important;
    color: #fff !important;
    transition: all 0.2s ease;
    min-width: 0 !important;
    border-radius: 50px !important;
    overflow: hidden;
    position: relative;

    // &:hover {
    //     background: #fff !important;
    //     color: #df664e  !important;
    // }
    &::after {
        content: '';
        height: 100%;
        width: 100%;
        background-color: #fff;
        display: block;
        position: absolute;
        top: 0;
        left: 100%;
        transition: all 0.5s ease;
        z-index: 0;
    }

    span {
        position: relative;
        z-index: 1;
        color: #fff !important;
        font-family: 'Lexend Deca', sans-serif !important;
        font-size: 14px;
        font-weight: 500 !important;
    }

    &:hover::after {
        left: 0;
        border-radius: 50px !important;
    }

    &:hover {
        color: #63120d !important;

        span {
            color: #63120d !important;
        }
    }
}

.btn-border {
    font-family: 'Lexend Deca', sans-serif !important;
    background: transparent !important;
    border-radius: 5px !important;
    padding: 8px 30px !important;
    font-size: 14px;
    font-weight: 500 !important;
    border: 2px solid #63120d;
    color: #63120d !important;
    transition: all 0.5s ease;
    min-width: 0 !important;
    border-radius: 50px !important;
    overflow: hidden;
    position: relative;
    text-transform: uppercase !important;

    &:hover {
        background: #63120d !important;
        color: #fff !important;
    }

    &::after {
        content: '';
        height: 100%;
        width: 100%;
        background-color: #63120d;
        display: block;
        position: absolute;
        top: 0;
        left: 100%;
        transition: all 0.5s ease;
        z-index: -1;
    }

    span {
        position: relative;
        z-index: 1;
        color: #63120d !important;
        font-family: 'Lexend Deca', sans-serif !important;
        font-size: 14px;
        font-weight: 500 !important;
        text-transform: uppercase !important;
    }

    &:hover::after {
        left: 0;
        border-radius: 50px !important;
    }

    &:hover {
        color: #fff !important;

        span {
            color: #fff !important;
        }
    }
}

.contact-information-header {
    margin-right: 12px !important;
}

.videoTemplate {
    .MuiTabs-flexContainer {
        justify-content: center;

        button {
            font-family: 'Lexend Deca', sans-serif !important;
            text-transform: inherit;
            font-size: 16px;
            font-weight: 600 !important;
        }

        .Mui-selected {
            color: #63120d !important;
        }
    }

    .MuiTabs-indicator {
        background-color: #63120d !important;
        height: 3px;
    }
}

.videoTemplate-mediaList-Item {
    border: 1px solid #ddd;
    border-radius: 15px;
    overflow: hidden;
    transition: all 0.2s ease;
    height: 100%;

    h6 {
        padding: 15px 20px;
        margin: 0;
        font-size: 18px !important;
        line-height: 26px !important;
        font-weight: 600 !important;
        color: #2e475d;
        cursor: pointer;
    }

    &:hover {
        box-shadow: 0px 0px 22px -4px rgba(0, 0, 0, 0.28);
        border-color: #fff;
    }
}

.videoTemplate-mediaList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    width: 100%;

    .videoMainBox {
        &>div {
            width: 100% !important;
            height: 245px !important;
        }
    }
}

.videoTabHeader {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
}

.filterList-alphabet {
    display: flex;
    flex-direction: revert;
    flex-wrap: wrap;
    gap: 15px;

    button {
        padding: 8px;
        border-radius: 6px;
        border: 1px solid #eee;
        font-size: 14px;
        font-weight: bolder !important;
        color: #000;
        margin: 0 !important;

        &:hover {
            background-color: #eee;
        }

        &.selectedAlphabet {
            color: #63120d;
            border-color: #63120d;
            background-color: #eee;
        }
    }
}

.navbar.navbar-nav .dropdown-item.links:focus {
    background-color: transparent !important;
}

.videoTemplate>.MuiContainer-root>div>.MuiBox-root {
    padding: 30px 0;
}

.videoTemplate>div:first-child {
    overflow-x: auto !important;

    .MuiTabs-root {
        overflow: initial !important;
    }
}

.healthEducationTemplate {
    padding: 65px 0;
}

.select-educationCountry {
    margin-top: 40px;

    h3 {
        font-size: 22px !important;
        color: #2e475d;

        span {
            font-size: 22px;
            font-weight: 700 !important;
            color: #63120d;
        }
    }
}

.CountryBlock {
    margin: 45px 0;

    &__name {
        margin: 0 0 30px !important;
        font-size: 22px !important;
        line-height: 26px !important;
        font-weight: 600 !important;
        color: #2e475d;
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 18px;
        cursor: pointer;
    }

    &__listItem {
        display: flex;
        align-items: center;

        &-image {
            height: 18px;
            margin-right: 10px;
            width: 18px;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }

        &-name {
            margin: 0;
            color: #2e475d;
            opacity: 80%;
            font-size: 15px;
            line-height: 22px;
        }
    }
}

.destinationTemplate {
    padding: 60px 0;

    &__title {
        margin: 0 0 40px !important;
        font-size: 32px !important;
        line-height: 40px !important;
        font-weight: 600 !important;
        color: #2e475d;
        
    }
}

.notFoundText {
    margin: 0 !important;
    color: #2e475d;
    opacity: 80%;
    font-size: 20px !important;
    line-height: 22px !important;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 100px 15px;
    text-align: center;
}

@media only screen and (max-width: 1440px) {
    .header-wrapper .navbar .nav-right li.nav-item {
        margin-right: 20px;

        &.language-block {
            margin-right: 0px;
        }
    }

    .navbar.navbar-nav button {
        padding: 8px 22px !important;
    }

    a.header-logo img {
        width: 50px;
    }

    .header-wrapper {
        .custom-container {
            max-width: 100% !important;
            padding: 0 15px !important;
        }
    }

    a.header-logo span {
        font-size: 25px;
        line-height: 28px;
    }

    a.header-logo .mycrozyliving {
        font-size: 12px;
    }

    .navbar.navbar-nav .language-block button {
        padding: 8px 8px !important;
    }
}

@media only screen and (max-width: 1200px) {
    .mediaPostBlock__group {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    .header-containt .navbar.navbar-nav a {
        font-size: 13px !important;
    }

    .navbar.navbar-nav button {
        padding: 8px 16px !important;
    }

    .contact-information-header {
        margin-right: 10px !important;
    }

    .language-block .MuiButton-label {
        font-size: 13px !important;
        line-height: 21px !important;
    }

    #btn-demo span {
        font-size: 13px;
    }

    .btn-border span {
        font-size: 13px;
    }

    .navbar.navbar-nav .language-block button {
        padding: 8px 0px !important;
    }

    a.header-logo img {
        width: 45px;
    }
}

@media only screen and (max-width: 991px) {
    .mediaPostBlock__group {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .header-navbar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header-toggler {
        button {
            background: #fff !important;
            border: 2px solid #63120d;
        }
    }

    .header-containt {
        .navbar.navbar-nav {
            padding-top: 20px;
            justify-content: flex-start;
            align-items: flex-start !important;
            margin-top: 20px;
            border-top: 1px solid #eee !important;
        }

        .nav-right li.nav-item {
            margin-right: 0;
            margin-bottom: 16px !important;
            width: 100%;
        }

        .justify-content-end {
            justify-content: flex-start !important;
        }

        .navbar.navbar-nav .language-block button {
            padding: 0px !important;
        }
    }

    .videoTemplate-mediaList {
        grid-template-columns: 1fr 1fr;
    }

    .CountryBlock__list {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 767px) {
    .mediaPostBlock__group {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .main-page-header {
        display: block;
    }

    .mediaSeries-header {
        padding: 48px 0px 10px;
    }

    .rhap_container .rhap_header {
        padding-left: 0;
        text-align: center;
        margin-bottom: 0;
        margin-top: 270px;
        font-size: 24px;
    }

    .videoEpisode-image {
        text-align: center;
        transform: inherit;
        top: 40px;

        img {
            margin: 0 auto;
            max-width: 200px;
        }
    }

    .rhap_container {
        align-items: flex-start;
    }

    .rhap_progress-section {
        margin-bottom: 18px;
    }

    .seriesPlayArea .rhap_controls-section {
        justify-content: space-between;
        align-items: center;
    }

    .rhap_controls-section button svg {
        font-size: 28px;
    }

    .rhap_controls-section .rhap_main-controls button svg {
        font-size: 32px;
    }

    .rhap_main {
        bottom: 15px;
    }

    .mainPage-head {
        display: block;
    }

    .mainPage-head .btn-block {
        float: none;
        margin-top: 25px;
    }

    .btn-block .links {
        float: none;
    }

    .filter-search-box {
        margin-left: 0;
        margin-right: 92px;
    }

    .mainPage-search-filter button {
        left: auto;
        right: 0;
    }

    .CountryBlock__list {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 600px) {
    .mediaPostBlock__group {
        grid-template-columns: 1fr 1fr;
        gap: 13px;
    }

    .videoTemplate-mediaList {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 450px) {
    .mediaPost__img {
        height: 145px !important;
    }

    .CountryBlock__list {
        grid-template-columns: 1fr;
    }
}

.videoTemplate > div.x-hidden {
    overflow-x: hidden !important;
}